import { FC } from 'react'

import { title, header, description } from '$blocks/common/auth.css'
import { AuthFactorCard, AuthFactorCardProps } from '$elements/AuthFactorCard'

import { Login2FaBlockProps } from '.'

export type ChoiceProps = Pick<Login2FaBlockProps, 'data'> &
  Pick<AuthFactorCardProps, 'onClick' | 'disabled'>

export const Choice: FC<ChoiceProps> = ({
  data: { available_factors = [] },
  onClick,
  disabled
}) => (
  <>
    <header className={header}>
      <h2 className={title}>2FA Verification</h2>

      <p className={description}>Choose your preferred verification method.</p>
    </header>

    <main role="list">
      {available_factors.map(data => (
        <AuthFactorCard
          key={data.type}
          role="listitem"
          data={data}
          onClick={onClick}
          disabled={disabled}
        />
      ))}
    </main>
  </>
)

export default Choice
