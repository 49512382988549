import { FC } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Button } from '@genie-fintech/ui/components'

import { requiredString } from '$hook-forms/general-validators'

import { container, footer } from './styles.css'

const schema = z.object({
  email: requiredString.email()
})

type TField = z.infer<typeof schema>

type Props = {
  email?: string
  loading?: boolean
  onCancel: VoidFunction
  onContinue: (values: TField) => void
}

const defaultValues: TField = { email: '' }

const ChangeEmailRequestForm: FC<Props> = ({
  email,
  loading,
  onCancel,
  onContinue
}) => {
  const { control, handleSubmit, reset } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues
  })

  return (
    <form onSubmit={handleSubmit(onContinue)} className={container}>
      {email && (
        <BaseText
          label="Current Email"
          required
          disabled
          inputProps={{
            readOnly: true,
            value: email,
            type: 'email'
          }}
        />
      )}

      <BaseHookFieldText
        label={email ? 'New Email' : 'Email'}
        name="email"
        control={control}
        inputProps={{ type: 'email' }}
        required
      />

      <footer className={footer}>
        <Button
          onClick={() => {
            reset(defaultValues)
            onCancel()
          }}
          disabled={loading}
          styleVariants={{ kind: 'neutral', size: 'small', type: 'outlined' }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          styleVariants={{ size: 'small' }}
          disabled={loading}
        >
          Continue
        </Button>
      </footer>
    </form>
  )
}

export default ChangeEmailRequestForm
