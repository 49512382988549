import { FC, useRef } from 'react'
import { useBoolean, useClickAway } from 'ahooks'

import { Icon } from '@genie-fintech/ui/icons'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { TagsNoRef } from '@genie-fintech/ui/types'

import AccountPreview, { AccountPreviewProps } from '$elements/AccountPreview'
import AppInfo from '$elements/AppInfo'

import useAutoTransition from '$browser/useAutoTransition'
import useSignal from '$actions/useSignal'
import useAppDetail from '$actions/useAppDetail'
import useTokenProfileLogout from '$actions/useTokenProfileLogout'

import { Profile } from '$services/api/auth'
import { getAlias } from '$services/user'

import { TokenDetail } from '$store/session/tokens'
import { with_tokens } from '$store/profiles'
import queryParams from '$store/queryParams'

import { title as titleStyle } from '$elements/AppInfo/styles.css'
import ExtraIcon from '$icons/ExtraIcon'

import {
  header,
  container,
  main,
  list,
  loaderControl,
  footer,
  addNew,
  manage,
  nav,
  options,
  previewMain,
  optionLogout,
  previewContainer,
  manageIcon,
  optionIcon,
  footerInner
} from './styles.css'
import { getPhone } from '$app/utilities'

export type AccountSelectionProps = {
  defaultTitle?: string
  defaultDescription?: string
  onSelect: (id: TokenDetail['id']) => void
  onAddNew: VoidFunction
}

export const AccountSelection: FC<AccountSelectionProps> = ({
  defaultTitle = 'Choose an account',
  onSelect,
  onAddNew
}) => {
  const [manageRef] = useAutoTransition()
  const footerRef = useRef(null)
  const listRef = useRef(null)

  const {
    params: { client_id }
  } = useSignal(queryParams)
  const targetApp = useAppDetail(client_id)
  const isAppTarget = !!client_id
  const isAppInfoLoading = isAppTarget && targetApp.loading

  // TODO: Revert this with the following component after launchpad API is working properly
  const isAppLogin = false
  // const isAppLogin = isAppInfoLoading || !!targetApp.data

  const appTitle = targetApp.data?.data.name
  const appLogo = targetApp.data?.data.logo
  const appDomain = targetApp.data?.data.domain

  const [isManageable, { toggle, setFalse }] = useBoolean()
  const data = useSignal(with_tokens)

  const hasTokens = !!data.length

  const manageProps: TagsNoRef<'button'> = {
    title: 'Logout or Remove account',
    type: 'button',
    className: manage({ active: isManageable })
  }

  useClickAway(setFalse, [listRef, footerRef])

  return (
    <section className={container} data-testid="account-selection">
      <header className={header}>
        {isAppLogin && (
          <AppInfo
            isLoading={isAppInfoLoading}
            domain={appDomain}
            logo={appLogo}
            title={appTitle}
          />
        )}

        {!isAppLogin && <h2 className={titleStyle}>{defaultTitle}</h2>}
      </header>

      <nav className={nav}>
        <Button
          styleVariants={{ type: 'text', size: 'small' }}
          name="use-another-account"
          className={addNew}
          onClick={onAddNew}
        >
          Use another account
          <Icon namespace="Add" />
        </Button>
      </nav>

      {hasTokens && (
        <main className={main}>
          <div className="sticky fade-out" />

          <div className={list} ref={listRef}>
            {data.map(({ id, loading, data: profile, token }) => (
              <Preview
                key={id}
                isManageable={isManageable}
                containerProps={{
                  onClick: () => onSelect(id)
                }}
                loading={loading}
                profile={profile}
                token={token}
              />
            ))}
          </div>

          <div className="sticky fade-in" />
        </main>
      )}

      <footer className={footer} ref={footerRef}>
        <div className={footerInner} ref={manageRef}>
          {!isManageable && (
            <button name="remove-account" {...manageProps} onClick={toggle}>
              <ExtraIcon name="grear" className={manageIcon} />
              <span>Remove an account</span>
            </button>
          )}

          {isManageable && (
            <button
              name="cancel-remove-account"
              {...manageProps}
              onClick={toggle}
            >
              Cancel
            </button>
          )}
        </div>
      </footer>
    </section>
  )
}

type PreviewProps = Pick<AccountPreviewProps, 'containerProps'> & {
  isManageable: boolean
  loading?: boolean
  profile?: Profile
  token?: TokenDetail['token']
}

const Preview: FC<PreviewProps> = ({
  token,
  containerProps,
  isManageable,
  profile,
  loading: profileRefreshing
}) => {
  const [contRef] = useAutoTransition()

  const { loading: logoutLoading, logout } = useTokenProfileLogout(
    token,
    profile
  )

  const isLoading = profileRefreshing

  const disabled = isManageable
  const disabledRemove = !isManageable || logoutLoading

  return (
    <article ref={contRef} className={previewContainer}>
      {isLoading && (
        <div className={loaderControl}>
          <Spinner />
        </div>
      )}

      {profile && (
        <main
          className={previewMain({ inactivePreview: isManageable })}
          data-testid={`account-id:${profile.id} email:${profile.email}`}
        >
          <AccountPreview
            name={profile.name}
            username={getAlias({
              email: profile.email,
              phone: getPhone(profile)
            })}
            image={profile.profile_photo_url}
            containerProps={{ ...containerProps, disabled, name: 'select' }}
          />

          <aside className={options({ active: isManageable })}>
            <button
              type="button"
              name="logout"
              className={optionLogout({ loading: logoutLoading })}
              title="Logout this session"
              disabled={disabledRemove}
              onClick={logout}
            >
              {logoutLoading ? (
                <Spinner color="inherit" className={optionIcon} />
              ) : (
                <Icon namespace="Trash" className={optionIcon} />
              )}
              <span>{logoutLoading ? 'Removing...' : 'Remove'}</span>
            </button>
          </aside>
        </main>
      )}
    </article>
  )
}

export default AccountSelection
