import { FC, PropsWithChildren, useMemo } from 'react'

import { trueOrUndefined } from '@genie-fintech/ui/functions'

import useLayoutClass from '$browser/useLayoutClass'
import useRelativeBounding from '$browser/useRelativeBounding'
import useSignal from '$actions/useSignal'

import AuthLogo from '$icons/AuthLogo'
import BgCover from '$elements/BgCover'
import Link from '$elements/Link'
import CopyrightText from '$elements/CopyrightText'

import { profile as profileStore } from '$store/profile'
import { getAlias } from '$services/user'

import Nav from './Nav'

import SidebarContents from './SidebarContents'
import MobileSidebarContents from './MobileSidebarContents'

import { LayoutProvider, useAuthenticatedLayout } from './context'

import {
  container,
  copyright,
  cover,
  footer,
  header,
  headerInner,
  mainContents,
  logo,
  main,
  sidebar,
  headerLeft,
  mobileSidebar
} from './styles.css'

const Layout: FC<PropsWithChildren> = ({ children }) => {
  useLayoutClass({ name: 'AuthenticatedLayout' })

  const { containerRef, ready, pageRegionRef, pageHeadRef } =
    useAuthenticatedLayout()

  const pageHead = useRelativeBounding(containerRef, pageHeadRef)
  const pageRegion = useRelativeBounding(containerRef, pageRegionRef)
  const coverHeight = useMemo(
    () => Math.max(pageRegion.top, pageHead.top + pageHead.target.height) + 50,
    [pageHead.target.height, pageHead.top, pageRegion.top]
  )

  const profile = useSignal(profileStore)
  const fig = { ...profile }

  return (
    <section
      ref={containerRef}
      className={container}
      data-ready={trueOrUndefined(ready)}
    >
      <aside className={sidebar}>
        <SidebarContents />
      </aside>

      <div className={mainContents}>
        <header className={header}>
          <div className={headerInner}>
            <div className={headerLeft}>
              <div className={mobileSidebar}>
                <MobileSidebarContents />
              </div>

              <Link to="root">
                <a>
                  <AuthLogo className={logo} />
                </a>
              </Link>
            </div>

            <Nav
              user={{
                loading: fig.loading,
                name: fig.data?.name,
                photo: fig.data?.profile_photo_url,
                alias: getAlias(fig.data)
              }}
            />
          </div>
        </header>

        <main className={main} children={children} />

        <footer className={footer}>
          <CopyrightText className={copyright} />
        </footer>

        <BgCover
          className={cover}
          style={{ height: coverHeight }}
          data-ready={trueOrUndefined(ready)}
        />
      </div>
    </section>
  )
}

export const AuthenticatedLayout: typeof Layout = props => (
  <LayoutProvider>
    <Layout {...props} />
  </LayoutProvider>
)

export default AuthenticatedLayout
