import { Fragment, useCallback, useMemo, useState } from 'react'
import { format } from 'date-fns'

import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Icon } from '@genie-fintech/ui/icons'

import DeviceIcon from '$icons/DeviceIcon'
import ExtraIcon from '$icons/ExtraIcon'

import { pluralize } from '$app/utilities'
import { GENERAL_TIME_FORMAT_WITH_TIMEZONE } from '$constants'
import { DEVICES, TDevice } from '$constants/devices'

import { LoginDevicesResponse } from '$services/api/auth'

import {
  container,
  deviceCard,
  deviceIcon,
  deviceInfo,
  divider,
  historyIcon,
  historyInfo,
  historyInfoContainer,
  item,
  line,
  loginHistoryCard,
  noDataText,
  sessions,
  subContainer,
  subTitleText,
  titleText
} from './styles.css'

export type LoginDevicesProps = {
  devices: LoginDevicesResponse['data']
}

const DeviceCard = ({
  selectedDeviceName,
  item,
  onClick
}: {
  selectedDeviceName?: TDevice
  item: LoginDevicesProps['devices'][number]
  onClick: (deviceName: TDevice) => void
}) => {
  const isMounted = useIsMounted()

  const deviceName = item.device

  const iconNameSpace = DEVICES.includes(deviceName) ? deviceName : 'mobile'

  return (
    <article
      className={deviceCard}
      data-ready={trueOrUndefined(isMounted)}
      onClick={() => onClick(deviceName)}
      data-active={trueOrUndefined(selectedDeviceName === deviceName)}
    >
      <DeviceIcon name={iconNameSpace} className={deviceIcon} />

      <article className={deviceInfo}>
        <h4 className={titleText}>{item.display_name}</h4>

        <p className={sessions}>
          {`${item.session_counts} ${pluralize(item.session_counts, { plural: 'sessions', singular: 'session' })}`}
        </p>
      </article>

      <Icon namespace="Right" style={{ width: 16 }} color="text.disabled" />
    </article>
  )
}

const LoginHistoryCard = ({
  data
}: {
  data: LoginDevicesProps['devices'][number]['items'][number]
}) => {
  const isMounted = useIsMounted()

  const deviceName = [data.brand, data.brand === 'Apple' ? '' : data.model]
    .filter(Boolean)
    .join(' ')

  return (
    <article
      className={loginHistoryCard}
      data-ready={trueOrUndefined(isMounted)}
    >
      <article>
        <h4 className={titleText}>{data.browser}</h4>
        <p className={subTitleText}>
          {[deviceName, data.platform].filter(Boolean).join(', ')}
        </p>
      </article>

      <article className={historyInfoContainer}>
        <article className={historyInfo}>
          <ExtraIcon name="clock" className={historyIcon} />
          <p className={item} style={{ flex: 1 }}>
            Login Activity
          </p>
          <p className={item} style={{ maxWidth: 200 }}>
            {data.last_login_at
              ? format(data.last_login_at, GENERAL_TIME_FORMAT_WITH_TIMEZONE)
              : '-'}
          </p>
        </article>

        <article className={historyInfo}>
          <ExtraIcon name="map-pin" className={historyIcon} />
          <p className={item} style={{ flex: 1 }}>
            Location
          </p>
          <p className={item} style={{ maxWidth: 200 }}>
            {data.location || '-'}
          </p>
        </article>

        <article className={historyInfo}>
          <ExtraIcon name="map-pin" className={historyIcon} />
          <p className={item} style={{ flex: 1 }}>
            IP Address
          </p>
          <p className={item} style={{ maxWidth: 200 }}>
            {data.ip_address || '-'}
          </p>
        </article>
      </article>
    </article>
  )
}

export const LoginDevices = ({ devices }: LoginDevicesProps) => {
  const isMounted = useIsMounted()

  const [selectedDeviceName, setSelectedDeviceName] = useState<
    TDevice | undefined
  >(devices[0]?.device)

  const onChangeDevice = useCallback((value: TDevice) => {
    setSelectedDeviceName(value)
  }, [])

  const login_history = useMemo(
    () => devices.find(d => d.device === selectedDeviceName)?.items ?? [],
    [devices, selectedDeviceName]
  )

  if (!devices.length) {
    return <p className={noDataText}>No Devices Found!</p>
  }

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <article className={subContainer}>
        {devices.map(device => (
          <DeviceCard
            key={device.device}
            item={device}
            onClick={onChangeDevice}
            selectedDeviceName={selectedDeviceName}
          />
        ))}
      </article>

      <span className={divider} />

      <article
        className={subContainer}
        style={{ gap: 2, maxHeight: 600, overflowY: 'auto' }}
      >
        {login_history.map((v, k) => {
          return (
            <Fragment key={v.id}>
              {k !== 0 && <span className={line} />}

              <LoginHistoryCard data={v} />
            </Fragment>
          )
        })}
      </article>
    </article>
  )
}

export default LoginDevices
