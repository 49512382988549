import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { svg } from '@genie-fintech/ui/icons/Icon/style.css'

import { getIcon } from './functions'
import { SIZE, Namespaces } from './constants'

export type ExtraIconProps = {
  name: Namespaces
} & TagsNoRef<'svg'>

export const ExtraIcon: FC<ExtraIconProps> = ({ name, ...props }) => (
  <svg width={SIZE} height={SIZE} {...propsWithClassNames(props, svg)}>
    <use xlinkHref={`#${getIcon(name)}`} />
  </svg>
)

export default ExtraIcon
