import { FC, PropsWithChildren, useEffect, useId, useRef } from 'react'
import { useBoolean, useClickAway, useRequest } from 'ahooks'

import { Portal } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import useRouter from '$actions/useRouter'
import useNoScrollY from '$browser/useNoScrollY'

import UpdatePasswordForm, {
  UpdatePasswordFormProps
} from '$hook-forms/UpdatePasswordForm'

import lazyToast from '$services/lazyToast'
import { UpdatePasswordRequest } from '$services/api/auth'
import api from '$model/api'

import { closeButton, h2, overlay, overlayContent, header } from './styles.css'

const { errorMessageResolver } = api.value.auth

const request = (data: UpdatePasswordRequest) => {
  const { updatePassword } = api.value.auth

  return updatePassword(data)
}

type ChangePasswordButtonProps = Omit<TagsNoRef<'button'>, 'onClick'>

export const ChangePasswordButton: FC<
  PropsWithChildren<ChangePasswordButtonProps>
> = props => {
  const id = useId()

  const {
    queryParams: { open_change_password },
    updateQueryParams
  } = useRouter()

  const overlayContentRef = useRef(null)
  const buttonRef = useRef(null)

  const [open, { toggle, setTrue, setFalse }] = useBoolean()

  const { loading, runAsync } = useRequest(request, { manual: true })

  useNoScrollY(`change-password-${id}`, open)
  useClickAway(setFalse, [overlayContentRef, buttonRef])

  const onSuccess: UpdatePasswordFormProps['onSuccess'] = (
    reset,
    { current_password, password }
  ) => {
    lazyToast(runAsync({ current_password, password }), {
      loading: 'Updating..',
      error: errorMessageResolver,
      success: 'Password update successful.'
    }).then(() => {
      reset()
      setFalse()
    })
  }

  // Auto open via query param
  useEffect(() => {
    if (open_change_password?.toLowerCase() !== 'true') return

    setTrue()
    updateQueryParams({ open_change_password: undefined })
  }, [open_change_password, updateQueryParams, setTrue])

  return (
    <>
      <Portal>
        <section
          role="dialog"
          aria-hidden={trueOrUndefined(!open)}
          className={overlay({ open })}
        >
          <article ref={overlayContentRef} className={overlayContent}>
            <header className={header}>
              <h2 className={h2}>Update password</h2>
              <button onClick={toggle} className={closeButton} type="button">
                <Icon namespace="Cross" />
              </button>
            </header>

            <main>
              <UpdatePasswordForm onSuccess={onSuccess} disabled={loading} />
            </main>
          </article>
        </section>
      </Portal>

      <button ref={buttonRef} type="button" onClick={toggle} {...props} />
    </>
  )
}

export default ChangePasswordButton
