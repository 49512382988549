import { useEffect, useRef } from 'react'

/**
 * Alternative to visibility hidden without actually hiding.
 * Useful to hide regions as in-accessible elements.
 */
export const useInert = <T extends HTMLElement>(enabled = false) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    if (!ref.current) return

    ref.current.inert = enabled
  }, [enabled])

  return ref
}

export default useInert
