import { FC, PropsWithChildren } from 'react'

import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { TagsNoRef } from '@genie-fintech/ui/types'

import { hideWhenEmpty } from '$styles/atoms.css'

import { useAuthenticatedLayout } from '../context'
import { container } from './styles.css'

type ElementProps = {
  containerProps?: TagsNoRef<'section'>
}

export const PageRegion: FC<PropsWithChildren<ElementProps>> = ({
  children,
  containerProps
}) => {
  const { pageRegionRef } = useAuthenticatedLayout()

  return (
    <section
      ref={pageRegionRef}
      children={children}
      {...propsWithClassNames(containerProps, hideWhenEmpty, container)}
    />
  )
}

export default PageRegion
