import { z } from 'zod'
import { isNaN, isObject, trimEnd, omitBy } from 'lodash-es'
import { utility } from 'rutter'
import { Nullish } from 'utility-types'

import { THEME_NAME } from '$constants'

export type TypeAppTitle = {
  title?: string
  suffix?: string
  addSuffix?: boolean
  returnAppTitle?: boolean
}

export const appTitle = `${import.meta.env.VITE_APP_TITLE}`

export const getAppTitle = (options: TypeAppTitle = {}): string => {
  const {
    title,
    suffix = appTitle,
    addSuffix = true,
    returnAppTitle = true
  } = options

  if (title === undefined && returnAppTitle) {
    return appTitle
  }

  if (addSuffix) {
    return `${title} - ${suffix}`
  }

  return title || ''
}

export const noProtocolUrl = (url: string) => {
  const protocol_1 = 'http://'
  const protocol_2 = 'https://'

  const stripped = url
    .split(protocol_1)
    .filter(val => val !== protocol_1)
    .join('')
    .split(protocol_2)
    .filter(val => val !== protocol_2)
    .join('')

  return trimEnd(stripped, '/')
}

export const clipboardCopyText = (value: string) =>
  navigator.clipboard.writeText(value)

export const isValidURL = (value: string) => {
  const protocoless = noProtocolUrl(value)
  const url = `https://${protocoless}`

  const isUrl = z.string().url().safeParse(url).success

  // Allow domain-less URL in dev mode
  // Such as localhost
  if (isUrl && import.meta.env.DEV) return true

  // Is valid domain
  if (isUrl) {
    const [domain] = protocoless.split('/')

    // Must have "." if valid
    return domain.split('.').length >= 2
  }

  return true
}

export const isOSX = () =>
  window.navigator.userAgent.toUpperCase().indexOf('MAC') >= 0

export const sequentialPromise = async (
  promises: (() => Promise<unknown>)[]
) => {
  for (const task of promises) {
    await task()
  }
}

export const wait = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const youMustWait = async <T>(
  fetch_delay: number,
  job: () => Promise<T>
): Promise<T> => {
  try {
    const now = new Date().getTime()

    const result: T = await job()

    const duration = now - new Date().getTime()
    const timeLeft = fetch_delay - duration
    if (timeLeft > 0) {
      await wait(timeLeft)
    }

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

export const stripObject = <T extends object>(
  value: T,
  elements: unknown[] = [undefined]
) => {
  const cloned = { ...value }

  for (const name in cloned) {
    const val = cloned[name as never]

    if (isObject(val)) {
      cloned[name as never] = stripObject(val)
    }

    if (elements.includes(val)) {
      delete cloned[name as never]
    }
  }

  return cloned
}

export const stringCasting = (val: unknown) => {
  if (val == '') {
    return undefined
  }

  return val
}

export const isAllTrue = (values: boolean[]) => {
  return values.filter(Boolean).length === values.length
}

export type URLBuilderOptions = utility.URLBuilderOptions
export const buildURL = utility.buildURL

export const getThemedData = <T>(data: Record<typeof THEME_NAME, T>) => {
  return data[THEME_NAME]
}

export const isNumeric = (value: unknown) => {
  const cond1 = isNaN(value)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const cond2 = isNaN(parseFloat(value))

  return !cond1 && !cond2
}

export const filterValues = <Data extends object>(
  data: Data,
  exclusions: unknown[] = [undefined, '']
) => omitBy(data, value => exclusions.includes(value))

export const valueOrUndefined = <T>(
  determiner: boolean | Nullish,
  value: T
) => {
  return determiner ? value : undefined
}

export type PluralizedText = { singular: string; plural: string }
export const pluralize = (count: number, text: PluralizedText) => {
  return count > 1 ? text.plural : text.singular
}

export const getPhone = ({
  phone_code,
  phone_no
}: {
  phone_code?: string
  phone_no?: string
}) => {
  const phone = `${phone_code ?? ''}${phone_no ?? ''}`
  return phone || undefined
}
