import { FC } from 'react'

import Link from '$elements/Link'

import useGetMenuLinks from '../hooks/useGetMenuLinks'

import {
  group,
  list,
  nav,
  link as linkStyle,
  linkContent,
  linkName,
  groupTitle
} from './style.css'

export const Menu: FC = () => {
  const linkGroups = useGetMenuLinks()

  return (
    <nav className={nav}>
      {linkGroups.map(({ title, links }, g) => (
        <div key={g} className={group}>
          {title && <h5 className={groupTitle}>{title}</h5>}

          {!!links.length && (
            <div role="list" className={list}>
              {links.map(({ routeName, name, icon }) => (
                <div key={routeName} role="listitem">
                  <Link to={routeName}>
                    <a className={linkStyle}>
                      <span className={linkContent}>
                        {icon}
                        <span className={linkName}>{name}</span>
                      </span>
                    </a>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </nav>
  )
}

export default Menu
