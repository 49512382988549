import { useBoolean } from 'ahooks'
import { FC } from 'react'
import { Root } from '@radix-ui/react-checkbox'

import { OneOffDataAttributes } from '../../types'

import { Icon } from '../../icons'

import { CommonFieldStateProps } from '../fields/common/types'
import { propsWithClassNames, trueOrUndefined } from '../../functions'

import { BoxProps, FieldInfo, InfoProps } from './types'

import {
  root,
  label as labelStyle,
  indicator,
  RootVariants,
  indicatorCheckIcon,
  indicatorIndeterminateIcon,
  indicatorControl,
  container,
  info,
  infoIcon,
  infoText
} from './style.css'

export type CheckboxProps = FieldInfo & {
  infoProps?: InfoProps
  styleVariants?: RootVariants
  boxProps?: BoxProps
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  required,
  disabled,
  error,
  success,
  infoProps,
  boxProps,
  styleVariants
}) => {
  const {
    onFocus: onFocusNative,
    onBlur: onBlurNative,
    ...restBoxProps
  } = { ...boxProps }

  const [isFocus, { setFalse, setTrue }] = useBoolean()

  const icon = infoProps?.icon
  const iconProps = infoProps?.iconProps
  const text = infoProps?.text

  const hasInfo = !!infoProps
  const hasLabel = !!label

  const containerStateProps: OneOffDataAttributes = {
    'data-full-width': trueOrUndefined(styleVariants?.fullWidth),
    'data-has-info': trueOrUndefined(hasInfo)
  }

  const stateProps: CommonFieldStateProps = {
    'data-success': trueOrUndefined(success),
    'data-error': trueOrUndefined(error),
    'data-disabled': trueOrUndefined(disabled),
    'data-focus': trueOrUndefined(isFocus)
  }

  const onFocus: BoxProps['onFocus'] = (...args) => {
    setTrue()
    onFocusNative?.(...args)
  }

  const onBlur: BoxProps['onBlur'] = (...args) => {
    setFalse()
    onBlurNative?.(...args)
  }

  return (
    <div {...containerStateProps} className={container}>
      <Root
        {...stateProps}
        {...{ disabled, onFocus, onBlur }}
        {...propsWithClassNames(restBoxProps, root(styleVariants))}
      >
        <span className={indicatorControl}>
          <span className={indicator}>
            <Icon namespace="Check" className={indicatorCheckIcon} />
            <Icon namespace="Minus" className={indicatorIndeterminateIcon} />
          </span>
        </span>

        {hasLabel && (
          <span className={labelStyle}>
            {label}
            {required && <span>*</span>}
          </span>
        )}
      </Root>

      {hasInfo && (
        <div className={info}>
          {icon && (
            <Icon
              namespace={icon}
              {...propsWithClassNames(iconProps, infoIcon)}
            />
          )}
          {text && <p className={infoText}>{text}</p>}
        </div>
      )}
    </div>
  )
}

export default Checkbox
