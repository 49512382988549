import { useRef } from 'react'

import useIsMounted from '$browser/useIsMounted'

export const useProviderValue = () => {
  const ready = useIsMounted()

  const containerRef = useRef(null)
  const pageHeadRef = useRef(null)
  const pageRegionRef = useRef(null)

  return { containerRef, pageHeadRef, pageRegionRef, ready }
}

export type Value = ReturnType<typeof useProviderValue>

export default useProviderValue
