import { useRequest } from 'ahooks'
import { mapValues, toNumber } from 'lodash-es'

import lazyToast from '$services/lazyToast'
import api from '$model/api'

import { MobileOTPRequestFormProps } from '$hook-forms/MobileOTPRequestForm'

import useRouter from './useRouter'
import { MobileOTPRequestRequest } from '$services/api/auth'

const { mobileOTPRequest, errorMessageResolver, successResolver } =
  api.value.auth

type OnSuccess = MobileOTPRequestFormProps['onSuccess']

export const useMobileOTPRequest = (): MobileOTPRequestFormProps => {
  const { redirect } = useRouter()

  const { loading, runAsync } = useRequest(mobileOTPRequest, { manual: true })

  const onSuccess: OnSuccess = async (reset, { is_whatsapp, ...data }) => {
    const payload: MobileOTPRequestRequest = {
      ...mapValues(data, toNumber),
      send_via: is_whatsapp ? 'whatsapp' : 'sms'
    }

    const {
      data: { reference_number }
    } = await lazyToast(runAsync(payload), {
      loading: 'Requesting..',
      error: e => errorMessageResolver(e, 'Invalid credential'),
      success: 'OTP requested'
    }).then(successResolver)

    reset()

    redirect('authSmsOtpVerify', ({ queryParams }) => ({
      queryParams: {
        // Carry current parameters
        ...queryParams,

        // Pass OTP ref number for retry
        reference_number
      }
    }))
  }

  return { onSuccess, disabled: loading }
}

export default useMobileOTPRequest
