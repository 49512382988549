export type TDevice = (typeof DEVICES)[number]

export const DEVICES = [
  'mobile',
  'desktop',
  'tablet',
  'api-client-tools',
  'robot',
  'unknown'
] as const
