import React, { useRef, useState } from 'react'
import { useBoolean, useClickAway, useDebounce, useRequest } from 'ahooks'

import { body, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button, Portal, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { toClassNames, trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useIsMounted from '$browser/useIsMounted'
import useSignal from '$actions/useSignal'

import ExtraIcon from '$icons/ExtraIcon'
import { ready } from '$layouts/styles.css'

import api from '$model/api'
import { refreshStates } from '$store/profiles'
import { timezones, Timezones } from '$store/settings'
import { Profile } from '$services/api/auth'
import lazyToast from '$services/lazyToast'
import { errorMessageResolver } from '$services/api/common'

import {
  backbuttonStyle,
  doubleConfirmMainContainer,
  selectedTimezoneContainer,
  emptyData,
  flexDirectionColumn,
  iconStyle,
  optionContainer,
  optionItem,
  optionLabel,
  overlay,
  overlayContent,
  searchInputContainerStyle,
  searchInputStyle,
  warningIconContainer,
  doubleConfirmFooterContainer,
  container
} from './styles.css'

const { colors } = themeVars

const Choice = ({
  currentTimezone,
  timezones,
  onChangeTimezone
}: {
  currentTimezone?: string
  timezones: Timezones
  onChangeTimezone: (value: Timezones[number]) => void
}) => {
  const isMounted = useIsMounted()

  const [searchValue, setSearchValue] = useState('')

  const debouncedSearch = useDebounce(searchValue, { wait: 500 })

  const options = timezones.filter(t =>
    t.value.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase())
  )

  const onClearSearch = (e: React.MouseEvent) => {
    e.stopPropagation()
    setSearchValue('')
  }

  const onOptionItemClick = (
    e: React.MouseEvent,
    timezone: Timezones[number]
  ) => {
    e.stopPropagation()
    onChangeTimezone(timezone)
  }

  return (
    <article className={ready} data-ready={trueOrUndefined(isMounted)}>
      <article className={searchInputContainerStyle}>
        <BaseText
          affix={{
            pre: <Icon namespace="Search" className={iconStyle} />,
            post: searchValue && (
              <Icon
                namespace="Cross"
                className={iconStyle}
                onClick={onClearSearch}
                style={{ cursor: 'pointer' }}
              />
            )
          }}
          elementControlProps={{ className: searchInputStyle }}
          inputProps={{
            value: searchValue,
            onChange: e => setSearchValue(e.currentTarget.value),
            autoFocus: true
          }}
        />
      </article>

      <article className={optionContainer}>
        {!options.length && <article className={emptyData}>No Data</article>}

        {options.map(v => {
          const isCurrent = v.key === currentTimezone

          return (
            <article
              key={v.key}
              className={optionItem}
              data-current={trueOrUndefined(isCurrent)}
              onClick={e => {
                if (isCurrent) return
                onOptionItemClick(e, v)
              }}
            >
              <span className={optionLabel}>{v.value}</span>

              {isCurrent && (
                <Icon
                  namespace="Check"
                  color="primary.default"
                  className={iconStyle}
                />
              )}
            </article>
          )
        })}
      </article>
    </article>
  )
}

const Confirm = ({
  selectedTimezone,
  clearSelectedTimezone,
  onCancel,
  onConfirm,
  loading
}: {
  selectedTimezone?: Timezones[number]
  clearSelectedTimezone: VoidFunction
  onCancel: VoidFunction
  onConfirm: (timezone: string) => void
  loading?: boolean
}) => {
  const isMounted = useIsMounted()

  const onBack = (e: React.MouseEvent) => {
    e.stopPropagation()
    clearSelectedTimezone()
  }

  return (
    <article
      className={toClassNames(flexDirectionColumn, ready)}
      style={{ flex: 1 }}
      data-ready={trueOrUndefined(isMounted)}
    >
      <main className={doubleConfirmMainContainer}>
        <article>
          <button
            className={backbuttonStyle}
            onClick={onBack}
            disabled={loading}
          >
            <Icon namespace="Backward" width={16} />
            Back
          </button>
        </article>

        <article>
          <span className={warningIconContainer}>
            <ExtraIcon name="alert-triangle" />
          </span>
        </article>

        <article className={flexDirectionColumn} style={{ gap: 4 }}>
          <h4 className={title.four}>You are about to change the timezone!</h4>

          <p className={body.three} style={{ color: colors.neutral[70] }}>
            You are changing the timezone to...
          </p>
        </article>

        <article className={selectedTimezoneContainer}>
          {selectedTimezone?.value}
        </article>
      </main>

      <footer className={doubleConfirmFooterContainer}>
        <Button
          onClick={onCancel}
          disabled={loading}
          styleVariants={{
            type: 'outlined',
            kind: 'neutral',
            size: 'small'
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => onConfirm(selectedTimezone?.key ?? '')}
          styleVariants={{ size: 'small' }}
          disabled={loading}
        >
          {loading && <Spinner />}
          Confirm
        </Button>
      </footer>
    </article>
  )
}

const request = (data: { timezone: string }) => {
  const { putTimezone } = api.value.auth

  return putTimezone(data)
}

export const TimezoneSetting = ({
  timezone
}: Pick<Partial<Profile>, 'timezone'>) => {
  const [open, { setTrue: openModal, setFalse: closeModal }] = useBoolean()

  const overlayContentRef = useRef(null)
  const selectInputRef = useRef(null)

  const [selectedTimezone, setSelectedTimezone] = useState<Timezones[number]>()

  const timezone_list = useSignal(timezones)

  const { loading, runAsync } = useRequest(request, { manual: true })

  const onClose = () => {
    setSelectedTimezone(undefined)
    closeModal()
  }

  useClickAway(onClose, [overlayContentRef, selectInputRef])

  const onConfirm = (timezone: string) => {
    lazyToast(runAsync({ timezone }), {
      loading: 'Updating..',
      error: errorMessageResolver,
      success: 'Timezone update successful.'
    }).then(() => {
      refreshStates()
      onClose()
    })
  }

  const state = (() => {
    if (!open) return ''

    return selectedTimezone ? 'confirm' : 'choice'
  })()

  return (
    <>
      <Portal>
        <section
          role="dialog"
          aria-hidden={trueOrUndefined(!open)}
          className={overlay({ open })}
        >
          <article ref={overlayContentRef} className={overlayContent}>
            {state === 'choice' && (
              <Choice
                currentTimezone={timezone}
                timezones={timezone_list}
                onChangeTimezone={setSelectedTimezone}
              />
            )}

            {state === 'confirm' && (
              <Confirm
                clearSelectedTimezone={() => setSelectedTimezone(undefined)}
                selectedTimezone={selectedTimezone}
                onCancel={onClose}
                onConfirm={onConfirm}
                loading={loading}
              />
            )}
          </article>
        </section>
      </Portal>

      <article className={container}>
        <BaseText
          label="Preferred Timezone"
          required
          affix={{ post: <Icon namespace="Down" className={iconStyle} /> }}
          fullWidth
          elementControlProps={{ style: { cursor: 'pointer' } }}
          inputProps={{
            readOnly: true,
            style: { pointerEvents: 'none' },
            onFocus: openModal,
            value: timezone_list.find(t => t.key === timezone)?.value ?? ''
          }}
          containerRef={selectInputRef}
        />
      </article>
    </>
  )
}

export default TimezoneSetting
