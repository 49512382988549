import { FC, useRef } from 'react'
import { useBoolean, useClickAway } from 'ahooks'

import { Button } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useAutoTransition from '$browser/useAutoTransition'
import useActiveProfileLogout from '$actions/useActiveProfileLogout'

import AccountSwitcher from '$blocks/AccountSwitcher'
import ProfileInfo, { ProfileInfoProps } from '$elements/ProfileInfo'
import Link from '$elements/Link'

import lazyToast from '$services/lazyToast'
import { errorMessageResolver } from '$services/api/common'

import {
  button,
  switcher,
  switcherContainer,
  switcherContent
} from './styles.css'

type NavProps = {
  user: ProfileInfoProps & {
    loading?: boolean
  }
}

export const Nav: FC<NavProps> = ({ user }) => {
  const [switcherContainerRef] = useAutoTransition()

  const buttonRef = useRef(null)
  const switcherRef = useRef(null)

  const [menuOpen, { toggle, setFalse }] = useBoolean()

  const { logout, loading } = useActiveProfileLogout()

  const doLogout = () => {
    const name = user.name || user.alias

    const successMessage = [name, 'session has been ended.']
      .filter(Boolean)
      .join(' ')

    lazyToast(logout(), {
      loading: 'Clearing session..',
      error: errorMessageResolver,
      success: successMessage
    })
  }

  useClickAway(setFalse, [switcherRef, buttonRef])

  return (
    <nav>
      <div ref={switcherContainerRef} className={switcherContainer}>
        <button
          ref={buttonRef}
          type="submit"
          name="account-switcher-toggle"
          className={button}
          onClick={toggle}
          data-loading={trueOrUndefined(user.loading)}
        >
          <ProfileInfo {...user} />

          <Icon namespace="Down" color="absolute.light" />
        </button>

        {menuOpen && (
          <div className={switcher} ref={switcherRef}>
            <div className={switcherContent}>
              <AccountSwitcher />

              <Link to="authLogin">
                <Button
                  name="use-another-account"
                  type="button"
                  disabled={loading}
                  styleVariants={{ size: 'small' }}
                >
                  Add another account
                </Button>
              </Link>

              <Button
                name="logout"
                type="button"
                disabled={loading}
                styleVariants={{ size: 'small', type: 'text', kind: 'danger' }}
                onClick={doLogout}
              >
                <Icon namespace="LogOut" /> Logout
              </Button>
            </div>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Nav
