import { useBoolean, useRequest } from 'ahooks'

import useSignal from '$actions/useSignal'

import session from '$store/session'
import { api } from '$model/api'

const request = () => {
  const { loginDevices, successResolver } = api.value.auth

  return loginDevices().then(successResolver)
}

export const useLoginDevices = () => {
  const { current: sessionID } = useSignal(session)

  // Track initial loading
  const [isDirty, { setTrue }] = useBoolean()

  const { data, loading, error } = useRequest(request, {
    // https://github.com/alibaba/hooks/issues/2356#issue-1977838612
    // cacheKey: `${sessionID}:app-list`,

    refreshDeps: [sessionID],
    refreshOnWindowFocus: true,
    onFinally: setTrue
  })
  const { data: devices = [] } = { ...data }

  const isLoading = !isDirty && loading
  const hasError = !!error === true
  const isEmpty = devices.length === 0 || hasError

  return {
    isLoading,
    isEmpty,
    devices
  }
}

export default useLoginDevices
