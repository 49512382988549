import { FC } from 'react'

import { Button } from '@genie-fintech/ui/components'

import OTPInput, { OTPInputProps } from '$elements/OTPInput'
import {
  description as descriptionStyle,
  header,
  title as titleStyle
} from '$blocks/common/auth.css'

import { LoginChallengeEmailResponse } from '$services/api/auth-2fa'

import { otp } from './styles.css'

export type OTPProps = Pick<
  OTPInputProps,
  'length' | 'onFilled' | 'disabled'
> & {
  refCode?: LoginChallengeEmailResponse['reference_code']
  onResend?: VoidFunction
  timerActive: boolean
  timerSeconds: number
  title: string
  description: string
}

export const OTP: FC<OTPProps> = ({
  onFilled,
  disabled,
  length,
  refCode,
  onResend,
  timerActive,
  timerSeconds,
  title,
  description
}) => (
  <>
    <header className={header}>
      <h2 className={titleStyle}>{title}</h2>

      <p className={descriptionStyle}>
        {description}
        {refCode && (
          <>
            Ref: (<span className={otp.highlightedText}>{refCode}</span>)
          </>
        )}
      </p>
    </header>

    <main className={otp.main}>
      <OTPInput
        className={otp.field}
        disabled={disabled}
        onFilled={onFilled}
        length={length}
        autoFocus
      />

      <div>
        {timerActive ? (
          <p className={otp.reSendText}>
            Resend in{' '}
            <span className={otp.highlightedText}>{timerSeconds}s</span>
          </p>
        ) : (
          <Button
            styleVariants={{ size: 'small' }}
            onClick={onResend}
            disabled={disabled}
          >
            Resend OTP
          </Button>
        )}
      </div>
    </main>
  </>
)

export default OTP
