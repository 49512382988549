import { FC, useEffect } from 'react'

import { Spinner } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import usePageClass from '$browser/usePageClass'
import useSignal from '$actions/useSignal'
import useRouter from '$actions/useRouter'

import PageHead from '$layouts/AuthenticatedLayout/PageHead'
import PageRegion from '$layouts/AuthenticatedLayout/PageRegion'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'

import TimezoneSetting from '$blocks/TimezoneSetting'

import { profile } from '$store/profile'

import Details from './Details'

import {
  content,
  header as headerClassName,
  nav as navClassName
} from './styles.css'

const pageName = 'ProfileDetail'

export const Apps: FC = () => {
  usePageClass({ name: pageName })

  const {
    queryParams: { open_change_password },
    redirectWithQueryParams
  } = useRouter()

  const profileStore = useSignal(profile)
  const { loading, data } = { ...profileStore }

  const { name, address, email, phone, timezone } = { ...data }

  // Backward-compatibility for old web-auth sdk versions.
  useEffect(() => {
    if (!open_change_password) return

    redirectWithQueryParams('profilePassword')
  }, [open_change_password, redirectWithQueryParams])

  return (
    <>
      <PageHead
        title="User Details"
        description="Manage and review user accounts for efficient system administration."
      />

      <PageRegion>
        <PageContents
          title="Information"
          description="Some of the user informations can be editable."
          mainClassName={content}
          mainProps={{ 'data-loading': trueOrUndefined(loading) }}
        >
          {loading ? (
            <Spinner
              color="neutral.100"
              style={{
                alignSelf: 'center',
                justifySelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            />
          ) : (
            <>
              <Details title="User Name" data-testid="profile-info-name">
                {name}
              </Details>

              <Details title="Email Address" data-testid="profile-info-email">
                {email}
              </Details>

              <Details title="Phone Number" data-testid="profile-info-phone">
                {phone}
              </Details>

              <Details title="Address" data-testid="profile-info-address">
                {address}
              </Details>
            </>
          )}
        </PageContents>

        <PageContents
          title="Configure Timezone"
          description="The system will provide reports aligned with your selected timezone."
          headerClassName={headerClassName}
          navProps={{ className: navClassName }}
          nav={<TimezoneSetting timezone={timezone} />}
        />
      </PageRegion>
    </>
  )
}

export default Apps
