import { cloneElement, createElement, forwardRef, isValidElement } from 'react'

import { trueOrUndefined } from '@genie-fintech/ui/functions'

import useRouter from '$actions/useRouter'
import { getDetail, redirect } from '$app/router'
import { buildURL, valueOrUndefined } from '$app/utilities'

type RedirectParams = Parameters<typeof redirect>

type RouteNames = RedirectParams[0]
type RedirectOptions = RedirectParams[1]

type Tag = JSX.IntrinsicElements['a']

export type LinkProps = {
  to: RouteNames
  options?: RedirectOptions
} & Omit<Tag, 'href'>

export const Link = forwardRef<Tag, LinkProps>(
  (
    {
      children,
      to,
      options,
      onClick: onNativeClick = () => undefined,
      ...props
    },
    ref
  ) => {
    const isJSX = isValidElement(children)

    const currentRoute = useRouter()

    const redirectOptions = (() => {
      if (typeof options == 'function') {
        const { params, hash, queryParams } = currentRoute

        return options({
          hash,
          params,
          queryParams
        })
      }

      return options
    })()

    const { pathname, isMatch, meta } = getDetail(to)
    const href = buildURL(pathname, redirectOptions).toString()

    const onClick: LinkProps['onClick'] = e => {
      onNativeClick(e)

      if (e.defaultPrevented) return

      e.preventDefault()

      redirect(to, redirectOptions)
    }

    const passProps = {
      href,
      onClick,
      ref,
      'data-active': trueOrUndefined(isMatch),
      title: valueOrUndefined(isJSX, meta?.title),
      ...props
    }

    if (isJSX) return cloneElement(children, passProps)

    return createElement('a', { children, ...passProps })
  }
)

export default Link
