import { FC, ReactNode } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import { useAuthenticatedLayout } from '../context'

import {
  container,
  description as descriptionStyle,
  title as titleStyle
} from './styles.css'

export type PageHeadProps = {
  containerProps?: TagsNoRef<'header'>

  title?: ReactNode
  titleProps?: TagsNoRef<'h1'>

  description?: ReactNode
  descriptionProps?: TagsNoRef<'div'>
}

export const PageHead: FC<PageHeadProps> = ({
  containerProps,
  description,
  descriptionProps,
  title,
  titleProps
}) => {
  const { pageHeadRef } = useAuthenticatedLayout()

  return (
    <header
      ref={pageHeadRef}
      {...propsWithClassNames(containerProps, container)}
    >
      {title && (
        <h1 {...propsWithClassNames(titleProps, titleStyle)} children={title} />
      )}

      {description && (
        <div
          {...propsWithClassNames(descriptionProps, descriptionStyle)}
          children={description}
        />
      )}
    </header>
  )
}

export default PageHead
