import { ReactNode } from 'react'

import { Icon } from '@genie-fintech/ui/icons'

import ExtraIcon from '$icons/ExtraIcon'

import { RouteNames } from '$app/router'
import useSignal from '$actions/useSignal'
import { profile } from '$store/profile'

type GrouppedMenuLinks = {
  title?: string
  links: {
    name: string
    routeName: RouteNames
    icon: ReactNode
  }[]
}[]

const MENU_LINKS: GrouppedMenuLinks = [
  {
    links: [
      {
        name: 'Access Applications',
        routeName: 'applications',
        icon: <ExtraIcon name="layer" />
      },
      {
        name: 'User Details',
        routeName: 'profileDetail',
        icon: <Icon namespace="User" />
      }
    ]
  },

  {
    title: 'Security',
    links: [
      {
        name: 'Devices Login',
        routeName: 'loginHistory',
        icon: <ExtraIcon name="laptop-2" />
      },
      {
        name: 'Password Setting',
        routeName: 'profilePassword',
        icon: <Icon namespace="Lock" />
      }
    ]
  }
]

export const useGetMenuLinks = () => {
  const profileData = useSignal(profile)

  const hasPassword = !!profileData?.data?.email

  return MENU_LINKS.map(({ links, ...rest }) => ({
    ...rest,
    links: links.filter(({ routeName }) => {
      if (routeName == 'profilePassword' && !hasPassword) return false

      return true
    })
  }))
}

export default useGetMenuLinks
