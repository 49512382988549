import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'

import { getIcon } from './functions'
import { SIZE } from './constants'

const fillClipRuleProps: TagsNoRef<'path'> = {
  fillRule: 'evenodd',
  clipRule: 'evenodd'
}

const pathProps: TagsNoRef<'path'> = {
  fill: 'currentColor'
}

const symbolProps: TagsNoRef<'symbol'> = {
  fill: 'none',
  viewBox: `0 0 ${SIZE} ${SIZE}`
}

const Sprites: FC = () => (
  <svg width={0} height={0} style={{ display: 'none' }}>
    <symbol id={getIcon('desktop')} {...symbolProps}>
      <path
        d="M4.16683 2.16667C3.70659 2.16667 3.3335 2.53976 3.3335 3V9.66667C3.3335 10.1269 3.70659 10.5 4.16683 10.5H15.8335C16.2937 10.5 16.6668 10.1269 16.6668 9.66667V3C16.6668 2.53976 16.2937 2.16667 15.8335 2.16667H4.16683ZM1.66683 3C1.66683 1.61929 2.78612 0.5 4.16683 0.5H15.8335C17.2142 0.5 18.3335 1.61929 18.3335 3V9.66667C18.3335 11.0474 17.2142 12.1667 15.8335 12.1667H4.16683C2.78612 12.1667 1.66683 11.0474 1.66683 9.66667V3Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M0.833496 14.6667C0.833496 14.2064 1.20659 13.8333 1.66683 13.8333H18.3335C18.7937 13.8333 19.1668 14.2064 19.1668 14.6667C19.1668 15.1269 18.7937 15.5 18.3335 15.5H1.66683C1.20659 15.5 0.833496 15.1269 0.833496 14.6667Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('mobile')} {...symbolProps}>
      <path
        d="M2.83374 2.50016C2.3735 2.50016 2.00041 2.87326 2.00041 3.3335V16.6668C2.00041 17.1271 2.3735 17.5002 2.83374 17.5002H11.1671C11.6273 17.5002 12.0004 17.1271 12.0004 16.6668V3.3335C12.0004 2.87326 11.6273 2.50016 11.1671 2.50016H2.83374ZM0.33374 3.3335C0.33374 1.95278 1.45303 0.833496 2.83374 0.833496H11.1671C12.5478 0.833496 13.6671 1.95278 13.6671 3.3335V16.6668C13.6671 18.0475 12.5478 19.1668 11.1671 19.1668H2.83374C1.45303 19.1668 0.33374 18.0475 0.33374 16.6668V3.3335Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M6.16707 15.0002C6.16707 14.5399 6.54017 14.1668 7.00041 14.1668H7.00874C7.46898 14.1668 7.84207 14.5399 7.84207 15.0002C7.84207 15.4604 7.46898 15.8335 7.00874 15.8335H7.00041C6.54017 15.8335 6.16707 15.4604 6.16707 15.0002Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('tablet')} {...symbolProps}>
      <path
        d="M3 2.50016C2.53976 2.50016 2.16667 2.87326 2.16667 3.3335V16.6668C2.16667 17.1271 2.53976 17.5002 3 17.5002H13C13.4602 17.5002 13.8333 17.1271 13.8333 16.6668V3.3335C13.8333 2.87326 13.4602 2.50016 13 2.50016H3ZM0.5 3.3335C0.5 1.95278 1.61929 0.833496 3 0.833496H13C14.3807 0.833496 15.5 1.95278 15.5 3.3335V16.6668C15.5 18.0475 14.3807 19.1668 13 19.1668H3C1.61929 19.1668 0.5 18.0475 0.5 16.6668V3.3335Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M7.16667 15.0002C7.16667 14.5399 7.53976 14.1668 8 14.1668H8.00833C8.46857 14.1668 8.84167 14.5399 8.84167 15.0002C8.84167 15.4604 8.46857 15.8335 8.00833 15.8335H8C7.53976 15.8335 7.16667 15.4604 7.16667 15.0002Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('api-client-tools')} {...symbolProps}>
      <path
        d="M14.4107 4.07763C14.7361 3.7522 15.2637 3.7522 15.5892 4.07763L18.9225 7.41097C19.2479 7.7364 19.2479 8.26404 18.9225 8.58948L15.5892 11.9228C15.2637 12.2482 14.7361 12.2482 14.4107 11.9228C14.0852 11.5974 14.0852 11.0697 14.4107 10.7443L17.1547 8.00022L14.4107 5.25615C14.0852 4.93071 14.0852 4.40307 14.4107 4.07763Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M5.58917 4.07763C5.91461 4.40307 5.91461 4.93071 5.58917 5.25615L2.8451 8.00022L5.58917 10.7443C5.91461 11.0697 5.91461 11.5974 5.58917 11.9228C5.26374 12.2482 4.7361 12.2482 4.41066 11.9228L1.07733 8.58948C0.751893 8.26404 0.751893 7.7364 1.07733 7.41097L4.41066 4.07763C4.7361 3.7522 5.26374 3.7522 5.58917 4.07763Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M12.3318 0.538156C12.7711 0.675433 13.0159 1.14283 12.8787 1.58212L8.71198 14.9155C8.57471 15.3547 8.10731 15.5996 7.66802 15.4623C7.22873 15.325 6.98391 14.8576 7.12118 14.4183L11.2879 1.08499C11.4251 0.645706 11.8925 0.400879 12.3318 0.538156Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('unknown')} {...symbolProps}>
      <path
        d="M8.73328 0.99523C9.11538 0.772581 9.5497 0.655273 9.99193 0.655273C10.4342 0.655273 10.8685 0.772581 11.2506 0.99523C11.6322 1.2176 11.9481 1.53709 12.1661 1.92119L12.1669 1.92264L18.8303 13.5835L18.8311 13.5849C19.0499 13.9644 19.1651 14.3948 19.1652 14.8329C19.1653 15.2715 19.05 15.7024 18.8309 16.0824C18.6118 16.4624 18.2966 16.778 17.9169 16.9976C17.5372 17.2172 17.1064 17.3331 16.6678 17.3335H3.33702C2.89768 17.3368 2.46522 17.2242 2.08322 17.007C1.70003 16.7892 1.38124 16.474 1.15916 16.0933C0.93707 15.7125 0.819586 15.2799 0.81861 14.8391C0.817635 14.3991 0.932841 13.9665 1.15257 13.5853L1.1536 13.5835L7.81693 1.92264L7.81776 1.92118C8.03578 1.53709 8.35166 1.2176 8.73328 0.99523ZM9.99193 2.32194C9.84452 2.32194 9.69975 2.36104 9.57238 2.43526C9.44501 2.50948 9.33961 2.61615 9.26693 2.7444L9.26547 2.74697L2.59694 14.4169C2.52348 14.5441 2.48495 14.6885 2.48527 14.8354C2.4856 14.9824 2.52476 15.1266 2.59879 15.2535C2.67282 15.3804 2.77908 15.4855 2.90681 15.5581C3.03454 15.6307 3.17919 15.6682 3.3261 15.6669L3.3336 15.6668L16.6661 15.6669C16.8123 15.6667 16.9559 15.6281 17.0824 15.5549C17.209 15.4817 17.3141 15.3765 17.3871 15.2498C17.4602 15.1232 17.4986 14.9795 17.4986 14.8333C17.4985 14.6871 17.46 14.5435 17.3869 14.4169L17.3851 14.4136L10.7184 2.74697L10.7169 2.7444C10.6443 2.61615 10.5389 2.50947 10.4115 2.43526C10.2841 2.36104 10.1393 2.32194 9.99193 2.32194Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M10.0003 5.66685C10.4605 5.66685 10.8336 6.03995 10.8336 6.50019V9.83352C10.8336 10.2938 10.4605 10.6669 10.0003 10.6669C9.54003 10.6669 9.16693 10.2938 9.16693 9.83352V6.50019C9.16693 6.03995 9.54003 5.66685 10.0003 5.66685Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M9.16693 13.1669C9.16693 12.7066 9.54003 12.3335 10.0003 12.3335H10.0086C10.4688 12.3335 10.8419 12.7066 10.8419 13.1669C10.8419 13.6271 10.4688 14.0002 10.0086 14.0002H10.0003C9.54003 14.0002 9.16693 13.6271 9.16693 13.1669Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('robot')} {...symbolProps}>
      <path
        d="M1.56548 1.23223C2.03433 0.763392 2.67021 0.5 3.33325 0.5H13.3333C13.9963 0.5 14.6322 0.763392 15.101 1.23223C15.5699 1.70107 15.8333 2.33696 15.8333 3V4.66667C15.8333 5.1269 15.4602 5.5 14.9999 5.5C14.5397 5.5 14.1666 5.1269 14.1666 4.66667V3C14.1666 2.77899 14.0788 2.56702 13.9225 2.41074C13.7662 2.25446 13.5543 2.16667 13.3333 2.16667H3.33325C3.11224 2.16667 2.90028 2.25446 2.744 2.41074C2.58772 2.56702 2.49992 2.77899 2.49992 3V8.83333C2.49992 9.05435 2.58772 9.26631 2.744 9.42259C2.90028 9.57887 3.11224 9.66667 3.33325 9.66667H9.99992C10.4602 9.66667 10.8333 10.0398 10.8333 10.5C10.8333 10.9602 10.4602 11.3333 9.99992 11.3333H3.33325C2.67021 11.3333 2.03433 11.0699 1.56548 10.6011C1.09664 10.1323 0.833252 9.49637 0.833252 8.83333V3C0.833252 2.33696 1.09664 1.70107 1.56548 1.23223Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M8.33325 9.7C8.79349 9.7 9.16658 10.0731 9.16658 10.5333V13.8333C9.16658 14.2936 8.79349 14.6667 8.33325 14.6667C7.87301 14.6667 7.49992 14.2936 7.49992 13.8333V10.5333C7.49992 10.0731 7.87301 9.7 8.33325 9.7Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M4.99992 13.8333C4.99992 13.3731 5.37301 13 5.83325 13H9.99992C10.4602 13 10.8333 13.3731 10.8333 13.8333C10.8333 14.2936 10.4602 14.6667 9.99992 14.6667H5.83325C5.37301 14.6667 4.99992 14.2936 4.99992 13.8333Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M14.9999 8.83333C14.5397 8.83333 14.1666 9.20643 14.1666 9.66667V14.6667C14.1666 15.1269 14.5397 15.5 14.9999 15.5H16.6666C17.1268 15.5 17.4999 15.1269 17.4999 14.6667V9.66667C17.4999 9.20643 17.1268 8.83333 16.6666 8.83333H14.9999ZM12.4999 9.66667C12.4999 8.28595 13.6192 7.16667 14.9999 7.16667H16.6666C18.0473 7.16667 19.1666 8.28595 19.1666 9.66667V14.6667C19.1666 16.0474 18.0473 17.1667 16.6666 17.1667H14.9999C13.6192 17.1667 12.4999 16.0474 12.4999 14.6667V9.66667Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>
  </svg>
)

export default Sprites
