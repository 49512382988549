import { FC, PropsWithChildren, createContext, useContext } from 'react'

import { Value, useProviderValue } from './useProviderValue'

const context = createContext<Value>(undefined!)

export const useAuthenticatedLayout = () => useContext(context)

export const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useProviderValue()

  return <context.Provider value={value}>{children}</context.Provider>
}
