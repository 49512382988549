import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'

import { getIcon } from './functions'
import { SIZE } from './constants'

const fillClipRuleProps: TagsNoRef<'path'> = {
  fillRule: 'evenodd',
  clipRule: 'evenodd'
}

const pathProps: TagsNoRef<'path'> = {
  fill: 'currentColor'
}

const symbolProps: TagsNoRef<'symbol'> = {
  fill: 'none',
  viewBox: `0 0 ${SIZE} ${SIZE}`
}

const Sprites: FC = () => (
  <svg width={0} height={0} style={{ display: 'none' }}>
    <symbol id={getIcon('development')} {...symbolProps}>
      <path
        d="M11.5284 4.86186C11.7888 4.60151 12.2109 4.60151 12.4712 4.86186L15.1379 7.52853C15.3983 7.78888 15.3983 8.21099 15.1379 8.47134L12.4712 11.138C12.2109 11.3984 11.7888 11.3984 11.5284 11.138C11.2681 10.8777 11.2681 10.4555 11.5284 10.1952L13.7237 7.99993L11.5284 5.80467C11.2681 5.54432 11.2681 5.12221 11.5284 4.86186Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M4.47124 4.86186C4.73159 5.12221 4.73159 5.54432 4.47124 5.80467L2.27598 7.99993L4.47124 10.1952C4.73159 10.4555 4.73159 10.8777 4.47124 11.138C4.21089 11.3984 3.78878 11.3984 3.52843 11.138L0.861766 8.47134C0.601417 8.21099 0.601417 7.78888 0.861766 7.52853L3.52843 4.86186C3.78878 4.60151 4.21089 4.60151 4.47124 4.86186Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M9.86535 2.03028C10.2168 2.1401 10.4126 2.51402 10.3028 2.86545L6.96949 13.5321C6.85967 13.8835 6.48575 14.0794 6.13432 13.9696C5.78289 13.8598 5.58703 13.4858 5.69685 13.1344L9.03018 2.46775C9.14001 2.11632 9.51392 1.92046 9.86535 2.03028Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('production')} {...symbolProps}>
      <path
        d="M13.8047 3.52851C14.0651 3.78886 14.0651 4.21097 13.8047 4.47132L6.4714 11.8047C6.21106 12.065 5.78894 12.065 5.5286 11.8047L2.19526 8.47132C1.93491 8.21097 1.93491 7.78886 2.19526 7.52851C2.45561 7.26816 2.87772 7.26816 3.13807 7.52851L6 10.3904L12.8619 3.52851C13.1223 3.26816 13.5444 3.26816 13.8047 3.52851Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('staging')} {...symbolProps}>
      <path
        d="M7.3335 2.66659C4.75617 2.66659 2.66683 4.75592 2.66683 7.33325C2.66683 9.91058 4.75617 11.9999 7.3335 11.9999C9.91083 11.9999 12.0002 9.91058 12.0002 7.33325C12.0002 4.75592 9.91083 2.66659 7.3335 2.66659ZM1.3335 7.33325C1.3335 4.01954 4.01979 1.33325 7.3335 1.33325C10.6472 1.33325 13.3335 4.01954 13.3335 7.33325C13.3335 10.647 10.6472 13.3333 7.3335 13.3333C4.01979 13.3333 1.3335 10.647 1.3335 7.33325Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M10.6621 10.6618C10.9224 10.4015 11.3446 10.4015 11.6049 10.6618L14.4716 13.5285C14.7319 13.7889 14.7319 14.211 14.4716 14.4713C14.2112 14.7317 13.7891 14.7317 13.5288 14.4713L10.6621 11.6047C10.4017 11.3443 10.4017 10.9222 10.6621 10.6618Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('uat')} {...symbolProps}>
      <path
        d="M14.6666 8.66675C15.0348 8.66675 15.3332 8.96522 15.3332 9.33341C15.3332 10.9247 14.7011 12.4508 13.5759 13.5761C12.4507 14.7013 10.9245 15.3334 9.33324 15.3334C8.96505 15.3334 8.66657 15.0349 8.66657 14.6667C8.66657 14.2986 8.96505 14.0001 9.33324 14.0001C10.5709 14.0001 11.7579 13.5084 12.6331 12.6332C13.5082 11.7581 13.9999 10.5711 13.9999 9.33341C13.9999 8.96522 14.2984 8.66675 14.6666 8.66675Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M9.25236 5.25253C9.62743 4.87746 10.1361 4.66675 10.6666 4.66675C11.197 4.66675 11.7057 4.87746 12.0808 5.25253C12.4559 5.62761 12.6666 6.13631 12.6666 6.66675V7.33341C12.6666 7.7016 12.3681 8.00008 11.9999 8.00008C11.6317 8.00008 11.3332 7.7016 11.3332 7.33341V6.66675C11.3332 6.48994 11.263 6.32037 11.138 6.19534C11.013 6.07032 10.8434 6.00008 10.6666 6.00008C10.4898 6.00008 10.3202 6.07032 10.1952 6.19534C10.0701 6.32037 9.99991 6.48994 9.99991 6.66675C9.99991 7.03494 9.70143 7.33341 9.33324 7.33341C8.96505 7.33341 8.66657 7.03494 8.66657 6.66675C8.66657 6.13631 8.87729 5.62761 9.25236 5.25253Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M6.58569 4.58587C6.96076 4.21079 7.46947 4.00008 7.99991 4.00008C8.53034 4.00008 9.03905 4.21079 9.41412 4.58587C9.78919 4.96094 9.99991 5.46965 9.99991 6.00008V6.66675C9.99991 7.03494 9.70143 7.33341 9.33324 7.33341C8.96505 7.33341 8.66657 7.03494 8.66657 6.66675V6.00008C8.66657 5.82327 8.59633 5.6537 8.47131 5.52868C8.34629 5.40365 8.17672 5.33341 7.99991 5.33341C7.82309 5.33341 7.65353 5.40365 7.5285 5.52868C7.40348 5.6537 7.33324 5.82327 7.33324 6.00008V6.66675C7.33324 7.03494 7.03476 7.33341 6.66657 7.33341C6.29838 7.33341 5.99991 7.03494 5.99991 6.66675V6.00008C5.99991 5.46965 6.21062 4.96094 6.58569 4.58587Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M3.91903 1.25253C4.2941 0.877462 4.80281 0.666748 5.33324 0.666748C5.86367 0.666748 6.37238 0.877462 6.74745 1.25253C7.12253 1.62761 7.33324 2.13631 7.33324 2.66675V6.33341C7.33324 6.7016 7.03476 7.00008 6.66657 7.00008C6.29838 7.00008 5.99991 6.7016 5.99991 6.33341V2.66675C5.99991 2.48994 5.92967 2.32037 5.80464 2.19534C5.67962 2.07032 5.51005 2.00008 5.33324 2.00008C5.15643 2.00008 4.98686 2.07032 4.86183 2.19534C4.73681 2.32037 4.66657 2.48994 4.66657 2.66675V9.33341C4.66657 9.7016 4.3681 10.0001 3.99991 10.0001C3.63172 10.0001 3.33324 9.7016 3.33324 9.33341V2.66675C3.33324 2.13632 3.54395 1.62761 3.91903 1.25253Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M13.3332 6.66675C13.1564 6.66675 12.9869 6.73699 12.8618 6.86201C12.7368 6.98703 12.6666 7.1566 12.6666 7.33341C12.6666 7.7016 12.3681 8.00008 11.9999 8.00008C11.6317 8.00008 11.3332 7.7016 11.3332 7.33341C11.3332 6.80298 11.544 6.29427 11.919 5.9192C12.2941 5.54413 12.8028 5.33341 13.3332 5.33341C13.8637 5.33341 14.3724 5.54413 14.7475 5.9192C15.1225 6.29427 15.3332 6.80298 15.3332 7.33341V9.33341C15.3332 10.9247 14.7011 12.4508 13.5759 13.5761C12.4507 14.7013 10.9245 15.3334 9.33324 15.3334H7.99991C5.95403 15.3334 4.65271 14.6882 3.53676 13.5797L3.53517 13.5782L1.13517 11.1782C1.12716 11.1701 1.11935 11.1619 1.11176 11.1535C0.767699 10.7725 0.58335 10.2737 0.596887 9.76046C0.610424 9.24724 0.82081 8.75887 1.18448 8.39648C1.54816 8.03409 2.03727 7.82544 2.55054 7.81372C3.06381 7.802 3.56193 7.98811 3.94176 8.33352C3.94956 8.34061 3.95719 8.34789 3.96464 8.35534L5.13798 9.52868C5.39833 9.78903 5.39833 10.2111 5.13798 10.4715C4.87763 10.7318 4.45552 10.7318 4.19517 10.4715L3.03494 9.31125C2.90962 9.20171 2.74769 9.1429 2.58097 9.1467C2.40988 9.15061 2.24685 9.22016 2.12562 9.34096C2.0044 9.46176 1.93427 9.62454 1.92976 9.79562C1.92537 9.962 1.98337 10.1238 2.09206 10.2494L4.47639 12.6338C4.47665 12.634 4.47691 12.6343 4.47717 12.6345C5.34768 13.4989 6.31295 14.0001 7.99991 14.0001H9.33324C10.5709 14.0001 11.7579 13.5084 12.6331 12.6332C13.5082 11.7581 13.9999 10.5711 13.9999 9.33341V7.33341C13.9999 7.1566 13.9297 6.98703 13.8046 6.86201C13.6796 6.73699 13.51 6.66675 13.3332 6.66675Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('local')} {...symbolProps}>
      <path
        d="M8 2.66659C6.52724 2.66659 5.33333 3.86049 5.33333 5.33325C5.33333 6.80601 6.52724 7.99992 8 7.99992C9.47276 7.99992 10.6667 6.80601 10.6667 5.33325C10.6667 3.86049 9.47276 2.66659 8 2.66659ZM4 5.33325C4 3.12411 5.79086 1.33325 8 1.33325C10.2091 1.33325 12 3.12411 12 5.33325C12 7.54239 10.2091 9.33325 8 9.33325C5.79086 9.33325 4 7.54239 4 5.33325Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M3.75736 9.75728C4.88258 8.63206 6.4087 7.99992 8 7.99992C9.5913 7.99992 11.1174 8.63206 12.2426 9.75728C13.3679 10.8825 14 12.4086 14 13.9999C14 14.3681 13.7015 14.6666 13.3333 14.6666C12.9651 14.6666 12.6667 14.3681 12.6667 13.9999C12.6667 12.7622 12.175 11.5753 11.2998 10.7001C10.4247 9.82492 9.23768 9.33325 8 9.33325C6.76232 9.33325 5.57534 9.82492 4.70017 10.7001C3.825 11.5753 3.33333 12.7622 3.33333 13.9999C3.33333 14.3681 3.03486 14.6666 2.66667 14.6666C2.29848 14.6666 2 14.3681 2 13.9999C2 12.4086 2.63214 10.8825 3.75736 9.75728Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('qa')} {...symbolProps}>
      <path
        d="M9.8049 5.52851C10.0653 5.78886 10.0653 6.21097 9.8049 6.47132L7.13823 9.13799C6.87788 9.39834 6.45577 9.39834 6.19543 9.13799L4.86209 7.80466C4.60174 7.54431 4.60174 7.1222 4.86209 6.86185C5.12244 6.6015 5.54455 6.6015 5.8049 6.86185L6.66683 7.72378L8.86209 5.52851C9.12244 5.26816 9.54455 5.26816 9.8049 5.52851Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M7.3335 2.66659C4.75617 2.66659 2.66683 4.75592 2.66683 7.33325C2.66683 9.91058 4.75617 11.9999 7.3335 11.9999C9.91083 11.9999 12.0002 9.91058 12.0002 7.33325C12.0002 4.75592 9.91083 2.66659 7.3335 2.66659ZM1.3335 7.33325C1.3335 4.01954 4.01979 1.33325 7.3335 1.33325C10.6472 1.33325 13.3335 4.01954 13.3335 7.33325C13.3335 10.647 10.6472 13.3333 7.3335 13.3333C4.01979 13.3333 1.3335 10.647 1.3335 7.33325Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M10.6621 10.6618C10.9224 10.4015 11.3446 10.4015 11.6049 10.6618L14.4716 13.5285C14.7319 13.7889 14.7319 14.211 14.4716 14.4713C14.2112 14.7317 13.7891 14.7317 13.5288 14.4713L10.6621 11.6047C10.4017 11.3443 10.4017 10.9222 10.6621 10.6618Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>

    <symbol id={getIcon('unknown')} {...symbolProps}>
      <path
        d="M8.00008 2C4.68637 2 2.00008 4.68629 2.00008 8C2.00008 11.3137 4.68637 14 8.00008 14C11.3138 14 14.0001 11.3137 14.0001 8C14.0001 4.68629 11.3138 2 8.00008 2ZM0.666748 8C0.666748 3.94991 3.94999 0.666666 8.00008 0.666666C12.0502 0.666666 15.3334 3.94991 15.3334 8C15.3334 12.0501 12.0502 15.3333 8.00008 15.3333C3.94999 15.3333 0.666748 12.0501 0.666748 8Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M8.00008 4.66667C8.36827 4.66667 8.66675 4.96514 8.66675 5.33333V8C8.66675 8.36819 8.36827 8.66667 8.00008 8.66667C7.63189 8.66667 7.33341 8.36819 7.33341 8V5.33333C7.33341 4.96514 7.63189 4.66667 8.00008 4.66667Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
      <path
        d="M7.33341 10.6667C7.33341 10.2985 7.63189 10 8.00008 10H8.00675C8.37494 10 8.67341 10.2985 8.67341 10.6667C8.67341 11.0349 8.37494 11.3333 8.00675 11.3333H8.00008C7.63189 11.3333 7.33341 11.0349 7.33341 10.6667Z"
        {...fillClipRuleProps}
        {...pathProps}
      />
    </symbol>
  </svg>
)

export default Sprites
