import { useState, useCallback, useEffect, RefObject } from 'react'
import { useSize, useScroll } from 'ahooks'

interface ElementBounding {
  x: number
  y: number
  top: number
  right: number
  bottom: number
  left: number
  width: number
  height: number
}

export function useElementBounding<T extends HTMLElement>(
  ref: RefObject<T | null>,
  options: {
    immediate?: boolean
    scrollTarget?: 'window' | 'element' | null
  } = {}
): ElementBounding {
  const { immediate = true, scrollTarget = 'window' } = options

  // Use ahooks useSize to track element dimensions reactively
  const elementSize = useSize(ref)

  // Track scroll position based on scrollTarget option
  const windowScroll = useScroll(scrollTarget === 'window' ? document : null)

  const elementScroll = useScroll(
    scrollTarget === 'element' && ref.current ? ref.current : null
  )

  const [bounds, setBounds] = useState<ElementBounding>({
    x: 0,
    y: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0
  })

  const update = useCallback(() => {
    const element = ref.current

    if (element) {
      const rect = element.getBoundingClientRect()

      setBounds({
        x: rect.x,
        y: rect.y,
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
        width: rect.width,
        height: rect.height
      })
    }
  }, [ref])

  useEffect(() => {
    // Update bounds when element size changes
    if (elementSize) {
      update()
    }
  }, [elementSize, update])

  useEffect(() => {
    // Initial update if immediate is true
    if (immediate) {
      update()
    }
  }, [update, immediate])

  // Trigger update on scroll based on selected scroll target
  useEffect(() => {
    if (scrollTarget === 'window' && windowScroll) {
      update()
    } else if (scrollTarget === 'element' && elementScroll) {
      update()
    }
  }, [windowScroll, elementScroll, scrollTarget, update])

  return bounds
}

export default useElementBounding
