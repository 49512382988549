import { FC } from 'react'
import { useRequest } from 'ahooks'

import { Portal } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useTimer from '$actions/useTimer'
import useInert from '$browser/useInert'

import lazyToast from '$services/lazyToast'
import {
  errorMessageResolver,
  errorStatusResolver,
  successResolver
} from '$services/api/common'
import { LoginResponseWith2FA } from '$services/api/auth'
import { api } from '$model/api'
import { Token } from '$store/session/tokens'

import Choice, { ChoiceProps } from './Choice'
import OTP, { OTPProps } from '../OTP'

import { closeButton, overlay, overlayContent } from './styles.css'

const { loginChallenge, loginResend, loginVerify } = api.value.auth['2Fa']

type DataProps = Partial<Omit<LoginResponseWith2FA, '2fa_required'>>

const challenge = (...args: Parameters<typeof loginChallenge>) => {
  return loginChallenge(...args).then(successResolver)
}
const resend = (...args: Parameters<typeof loginResend>) => {
  return loginResend(...args).then(successResolver)
}
const verify = (...args: Parameters<typeof loginVerify>) => {
  return loginVerify(...args).then(successResolver)
}

export type Login2FaBlockProps = {
  data: DataProps
  open?: boolean
  onVerified: (token: Token) => void
  onCancel: (reset: VoidFunction) => void
  onDismissToast: VoidFunction
}

const Block: FC<Login2FaBlockProps> = ({
  data,
  open,
  onVerified,
  onCancel,
  onDismissToast
}) => {
  const modalRef = useInert(!open)

  const otpTimer = useTimer(60 * 1000)

  const challengeReq = useRequest(challenge, { manual: true })
  const resendReq = useRequest(resend, { manual: true })
  const verifyReq = useRequest(verify, { manual: true })

  const requests = [challengeReq, resendReq, verifyReq]

  const { state_token } = data
  const challengeResult = challengeReq.data?.data

  const challenge_id = challengeResult?.challenge_id
  const type = challengeResult?.type

  const code_length = (() => {
    if (type == 'email') return challengeResult?.code_length
  })()
  const reference_code = (() => {
    if (type == 'email') return challengeResult?.reference_code
  })()

  const isAnyLoading = requests.map(({ loading }) => loading).includes(true)
  const disabled = !state_token || isAnyLoading

  const state = (() => {
    if (code_length) return 'otp'

    return 'choice'
  })()

  const onReset = () => {
    onCancel(() => {
      requests.forEach(({ cancel, mutate }) => {
        cancel()
        mutate()
      })
    })
  }

  const onUnauthorizedHandler = (err: unknown) => {
    if (errorStatusResolver(err) === 401) {
      onReset()
      return 'Session Timeout. Please login again!'
    }

    return errorMessageResolver(err)
  }

  const onSelect: ChoiceProps['onClick'] = ({ type }) => {
    if (!state_token) return

    const { runAsync, cancel } = challengeReq

    cancel()
    otpTimer.reset()

    onDismissToast()

    const successMessage = () => {
      if (type == 'email') return 'OTP requested.'

      return 'Requested.'
    }

    lazyToast(runAsync({ type, token: state_token }), {
      error: onUnauthorizedHandler,
      loading: 'Requesting session..',
      success: successMessage
    })
  }

  const onOTPResend: OTPProps['onResend'] = () => {
    if (!challenge_id || !state_token) return

    const { runAsync, cancel } = resendReq

    cancel()

    lazyToast(runAsync({ challenge_id, token: state_token }), {
      error: onUnauthorizedHandler,
      loading: 'Resending..',
      success: 'OTP requested'
    }).finally(() => {
      otpTimer.reset()
    })
  }

  const onOTPFilled: OTPProps['onFilled'] = async (code, resetInput) => {
    if (!type || !challenge_id || !state_token) return

    const { runAsync, cancel } = verifyReq

    cancel()

    const {
      data: { auth_token }
    } = await lazyToast(
      runAsync({ type, challenge_id, code, token: state_token }),
      {
        error: onUnauthorizedHandler,
        loading: 'Verifying..',
        success: 'Successfully logged in'
      }
    )

    resetInput()
    onVerified(auth_token)
  }

  return (
    <section
      ref={modalRef}
      role="dialog"
      aria-hidden={trueOrUndefined(!open)}
      className={overlay({ open })}
    >
      <article className={overlayContent}>
        <button type="button" className={closeButton} onClick={onReset}>
          <Icon namespace="Backward" /> Back
        </button>

        {state == 'choice' && (
          <Choice data={data} onClick={onSelect} disabled={disabled} />
        )}

        {state == 'otp' && (
          <OTP
            title="2FA Verification"
            description="We have sent you an 2FA code to your email."
            disabled={disabled}
            length={code_length}
            refCode={reference_code}
            onResend={onOTPResend}
            onFilled={onOTPFilled}
            timerActive={otpTimer.active}
            timerSeconds={otpTimer.seconds}
          />
        )}
      </article>
    </section>
  )
}

export const Login2FaBlock: FC<Login2FaBlockProps> = props => (
  <Portal>
    <Block {...props} />
  </Portal>
)

export default Login2FaBlock
