import { FC } from 'react'
import { useRequest } from 'ahooks'

import useRouter from '$actions/useRouter'

import Link from '$elements/Link'

import {
  suggestedLink,
  suggestedLinkContainer,
  suggestedLinkLabel
} from '$blocks/common/auth.css'

import lazyToast from '$services/lazyToast'
import api from '$model/api'

import ForgotPasswordForm, {
  ForgotPasswordFormProps
} from '$hook-forms/ForgotPasswordForm'

import {
  container,
  description,
  header,
  loginLinkLabel,
  title
} from './styles.css'

const { forgotPassword, errorMessageResolver } = api.value.auth

export const ForgotPassword: FC = () => {
  const { redirectWithQueryParams } = useRouter()

  const { loading, runAsync } = useRequest(forgotPassword, { manual: true })

  const onSuccess: ForgotPasswordFormProps['onSuccess'] = async (
    reset,
    data
  ) => {
    await lazyToast(
      runAsync({ ...data, request_origin: self.origin }),
      {
        loading: 'Requesting..',
        error: errorMessageResolver,
        success: 'Success! Please check your email for reset link.'
      },
      { duration: 5000 }
    )

    reset()

    // Retain ?return_to query
    redirectWithQueryParams('authLogin')
  }

  return (
    <section className={container}>
      <header className={header}>
        <h2 className={title}>Get password reset link</h2>

        <p className={description}>
          We will send the reset password link to your email inbox.
        </p>
      </header>

      <ForgotPasswordForm onSuccess={onSuccess} disabled={loading} />

      <div className={suggestedLinkContainer}>
        <p className={suggestedLinkLabel}>OR</p>

        <p>
          <span className={loginLinkLabel}>Remember your password?</span>{' '}
          <Link
            to="authLogin"
            // Retain ?return_to query
            options={({ queryParams }) => ({ queryParams })}
            className={suggestedLink}
          >
            Click here to log in.
          </Link>
        </p>
      </div>
    </section>
  )
}

export default ForgotPassword
