import { flatten } from 'lodash-es'
import { getCountryData, TCountryCode as CountryCode } from 'countries-list'

export type { TCountryCode as CountryCode } from 'countries-list'

export const allowedCountries = import.meta.env.VITE_ALLOWED_COUNTRIES.split(
  ','
) as CountryCode[]

export const allowedWhatsappCountries =
  import.meta.env.VITE_ALLOWED_WHATSAPP_COUNTRIES.split(',') as CountryCode[]

const countries = (() => {
  const raw = allowedCountries

  return raw.map(getCountryData)
})()

export type CallingCodes = typeof callingCodes
export const callingCodes = flatten(
  countries.map(({ phone, iso2, name }) =>
    phone.map(code => ({
      id: iso2,
      name,
      code
    }))
  )
).sort((a, b) => a.code - b.code)
