import { RefObject, useMemo } from 'react'
import { useElementBounding } from './useElementBounding'

export function useRelativeBounding<
  P extends HTMLElement,
  T extends HTMLElement
>(parentRef: RefObject<P | null>, targetRef: RefObject<T | null>) {
  const parentBounding = useElementBounding(parentRef)
  const targetBounding = useElementBounding(targetRef)

  // Use useMemo to calculate relative bounding only when references change
  return useMemo(
    () => ({
      parent: { width: parentBounding.width, height: parentBounding.height },
      target: { width: targetBounding.width, height: targetBounding.height },

      // Calculate relative positions
      top: targetBounding.top - parentBounding.top,
      bottom: parentBounding.bottom - targetBounding.bottom,
      left: targetBounding.left - parentBounding.left,
      right: parentBounding.right - targetBounding.right
    }),
    [parentBounding, targetBounding]
  )
}

export default useRelativeBounding
