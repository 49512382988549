import { TypeID } from '@genie-fintech/ui/types'

import { Response, ResponseResolver } from './types'
import { CommonAPI, toAuthorization } from './common'
import { AuthFactorType, LoginResponseNo2FA } from './auth'

/** ********************************************************************
 * Type: Common
 */
type Type<Factor extends AuthFactorType, T> = {
  type: Factor
} & T
type Request<T = unknown> = {
  token: string
} & T

/** ********************************************************************
 * Type: Login: Challenge
 */
type LoginChallengeRequest = Request<{
  type: AuthFactorType
}>
export type LoginChallengeEmailResponse = Type<
  'email',
  {
    reference_code: string
    expired_at: string
    code_length: number
  }
>
type LoginChallengeResponse = Response<
  {
    challenge_id: TypeID
  } & LoginChallengeEmailResponse
>

/** ********************************************************************
 * Type: Login: Resend
 */
type LoginResendRequest = Request<{
  challenge_id: TypeID
}>
type LoginResendResponse = LoginChallengeResponse

/** ********************************************************************
 * Type: Login: Verify
 */
type LoginVerifyRequest = Request<{
  challenge_id: TypeID
  code: string
  type: AuthFactorType
}>
type LoginVerifyResponse = Response<
  Omit<ResponseResolver<LoginResponseNo2FA>, '2fa_required'>
>

export class Auth2faAPI {
  private baseURL?: string

  constructor(baseURL?: Auth2faAPI['baseURL']) {
    this.baseURL = baseURL
  }

  private getNewApi = ({ token }: Request) => {
    const { api, filterRequestPayload } = new CommonAPI({
      baseURL: this.baseURL
    })

    if (token) {
      api.defaults.headers.common['Authorization'] = toAuthorization(token)
    }

    filterRequestPayload()

    return api
  }

  public loginChallenge = ({ token, ...data }: LoginChallengeRequest) => {
    return this.getNewApi({ token }).post<LoginChallengeResponse>(
      '/login/2fa/challenge',
      data
    )
  }

  public loginResend = ({ token, ...data }: LoginResendRequest) => {
    return this.getNewApi({ token }).post<LoginResendResponse>(
      '/login/2fa/resend',
      data
    )
  }

  public loginVerify = ({ token, ...data }: LoginVerifyRequest) => {
    return this.getNewApi({ token }).post<LoginVerifyResponse>(
      '/login/2fa/verify',
      data
    )
  }
}
