import { FC, useEffect, useRef } from 'react'

import { useBoolean, useClickAway } from 'ahooks'

import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'
import { Portal } from '@genie-fintech/ui/components'

import useNoScrollY from '$browser/useNoScrollY'
import useRouter from '$actions/useRouter'

import Menu from '../Menu'
import AccountInfo from '../AccountInfo'

import {
  aside,
  closeButton,
  footer,
  main,
  openButton,
  overlay,
  overlayContent
} from './style.css'

export const MobileSidebarContents: FC = () => {
  const { name } = useRouter()

  const contentRef = useRef(null)
  const buttonRef = useRef(null)

  const [open, { setTrue, setFalse }] = useBoolean()

  useNoScrollY('mobile-menu', open)
  useClickAway(setFalse, [contentRef, buttonRef])

  // Close upon route change
  useEffect(() => {
    setFalse()
  }, [name, setFalse])

  return (
    <>
      <Portal>
        <section
          role="dialog"
          aria-hidden={trueOrUndefined(!open)}
          className={overlay({ open })}
        >
          <article ref={contentRef} className={overlayContent}>
            <aside className={aside}>
              <AccountInfo />
            </aside>

            <main className={main}>
              <Menu />
            </main>

            <footer className={footer}>
              <button
                onClick={setFalse}
                className={closeButton}
                type="button"
                title="Close"
              >
                <Icon namespace="Cross" />
              </button>
            </footer>
          </article>
        </section>
      </Portal>

      <button
        ref={buttonRef}
        onClick={setTrue}
        className={openButton}
        type="button"
        title="Open Menu"
      >
        <Icon namespace="Menu" />
      </button>
    </>
  )
}

export default MobileSidebarContents
