import {
  CSSProperties,
  ComponentProps,
  FC,
  forwardRef,
  useMemo,
  useRef
} from 'react'
import { useSize } from 'ahooks'

import { Tabs } from '@genie-fintech/ui/components'

import { authMethods } from '$constants/auth'

import useEmailLogin from '$actions/useEmailLogin'
import useAppDetail from '$actions/useAppDetail'
import useSignal from '$actions/useSignal'

import LoginForm from '$hook-forms/LoginForm'
import MobileOTPRequestForm from '$hook-forms/MobileOTPRequestForm'
import useMobileOTPRequest from '$actions/useMobileOTPRequest'

import Link from '$elements/Link'
import AppInfo from '$elements/AppInfo'
import TabContentTransition from '$elements/TabContentTransition'

import {
  suggestedLink,
  suggestedLinkContainer,
  suggestedLinkLabel
} from '$blocks/common/auth.css'

import session from '$store/session'
import queryParams from '$store/queryParams'

import Message from './Message'

import {
  appInfo,
  container,
  forgotLink,
  header,
  tabListStyle,
  tabListTriggerStyle,
  title
} from './styles.css'
import Login2FaBlock from '$blocks/Login2FaBlock'
import { wait } from '$app/utilities'
import { TRANSITIONS } from '@genie-fintech/ui/constants'

const SessionLink: FC = () => {
  const { tokenDetails } = useSignal(session)

  const hasTokens = !!tokenDetails.length

  if (!hasTokens) return null

  return (
    <div className={suggestedLinkContainer}>
      <p className={suggestedLinkLabel}>OR</p>

      <Link
        to="authAccounts"
        data-testid="link-use-existing-sessions"
        options={({ queryParams }) => ({ queryParams })}
        className={suggestedLink}
      >
        Log in with existing accounts
      </Link>
    </div>
  )
}

const ForgotLink: FC = () => (
  <Link
    to="authForgotPassword"
    options={({ queryParams }) => ({ queryParams })}
    className={forgotLink}
  >
    Forgot password?
  </Link>
)

const TabContent = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof TabContentTransition>
>((props, ref) => (
  <TabContentTransition
    styleVariants={{ heightEffect: false }}
    containerRef={ref}
    {...props}
  />
))

export const LoginBlock: FC = () => {
  const {
    params: { client_id, auth_method }
  } = useSignal(queryParams)

  const targetApp = useAppDetail(client_id)
  const isAppTarget = !!client_id
  const isAppInfoLoading = isAppTarget && targetApp.loading

  // TODO: Revert this with the following component after launchpad API is working properly
  const isAppLogin = false
  // const isAppLogin = isAppInfoLoading || !!targetApp.data

  const appTitle = targetApp.data?.data.name
  const appLogo = targetApp.data?.data.logo
  const appDomain = targetApp.data?.data.domain

  const loginActions = useEmailLogin()
  const otpActions = useMobileOTPRequest()

  const emailBlockRef = useRef(null)
  const phoneBlockRef = useRef(null)
  const emailBlockSize = useSize(emailBlockRef)
  const phoneBlockSize = useSize(phoneBlockRef)
  const minContentHeight = useMemo(
    () => Math.max(emailBlockSize?.height || 0, phoneBlockSize?.height || 0),
    [emailBlockSize?.height, phoneBlockSize?.height]
  )
  const contentStyle: CSSProperties = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%'
  }

  return (
    <section className={container} data-testid="login-block">
      <header className={header}>
        <h2 className={title}>Log In</h2>
      </header>

      {isAppLogin && (
        <AppInfo
          isLoading={isAppInfoLoading}
          domain={appDomain}
          logo={appLogo}
          title={appTitle}
          containerProps={{ className: appInfo }}
        />
      )}

      <Tabs.Root defaultValue={auth_method} format={{ type: 'segmented' }}>
        <Tabs.List
          styleVariants={{ hAlign: 'center' }}
          className={tabListStyle}
        >
          <Tabs.Trigger value={authMethods[0]} className={tabListTriggerStyle}>
            Email
          </Tabs.Trigger>
          <Tabs.Trigger value={authMethods[1]} className={tabListTriggerStyle}>
            Phone Number
          </Tabs.Trigger>
        </Tabs.List>

        <div style={{ minHeight: minContentHeight, position: 'relative' }}>
          <Tabs.Content
            value={authMethods[0]}
            forceMount
            asChild
            data-testid={`tab-${authMethods[0]}`}
          >
            <TabContent contentRef={emailBlockRef} style={contentStyle}>
              <Message />

              <LoginForm
                {...loginActions.loginForm}
                preFooter={<ForgotLink />}
              />

              <SessionLink />
            </TabContent>
          </Tabs.Content>

          <Tabs.Content
            value={authMethods[1]}
            forceMount
            asChild
            data-testid={`tab-${authMethods[1]}`}
          >
            <TabContent contentRef={phoneBlockRef} style={contentStyle}>
              <MobileOTPRequestForm {...otpActions} />

              <SessionLink />
            </TabContent>
          </Tabs.Content>
        </div>
      </Tabs.Root>

      <Login2FaBlock
        open={loginActions.is2FAModalOpen}
        data={loginActions.secondFactor}
        onDismissToast={loginActions.forceDismissToast}
        onVerified={loginActions.onTokenSuccess}
        onCancel={async reset => {
          loginActions.close2FAModal()

          await wait(TRANSITIONS.GENERAL_DURATION)

          reset()
          loginActions.resetState()
        }}
      />
    </section>
  )
}

export default LoginBlock
