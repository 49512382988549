import { FC } from 'react'
import { useRequest } from 'ahooks'

import { Toast } from '@genie-fintech/ui/components'

import { serviceName } from '$constants/texts'
import { profile } from '$store/profile'
import { addToken } from '$store/session'

import useSignal from '$actions/useSignal'
import useReturnTo from '$actions/useReturnTo'

import lazyToast from '$services/lazyToast'
import api from '$model/api'
import { CommonAPI } from '$api/common'

import ReplacePasswordForm, {
  ReplacePasswordFormProps
} from '$hook-forms/ReplacePasswordForm'

import {
  container,
  descriptionHighlight,
  header,
  notification,
  title
} from './styles.css'

const { errorMessageResolver, successResolver } = new CommonAPI()

type RequestParams = Parameters<typeof api.value.auth.replacePassword>

const request = (...params: RequestParams) => {
  return api.value.auth.replacePassword(...params).then(successResolver)
}

export const ForgotPassword: FC = () => {
  const redirect = useReturnTo()

  const profileData = useSignal(profile)
  const emailValue = profileData?.data?.email
  const reason = profileData?.data?.should_replace_password_reason

  const { loading, runAsync } = useRequest(request, { manual: true })

  const reasonTitle = (() => {
    if (reason == 'expired_password') return 'Password Expired'

    return 'One More Step to Finish!'
  })()

  const onSuccess: ReplacePasswordFormProps['onSuccess'] = async (
    reset,
    data
  ) => {
    const {
      data: { auth_token }
    } = await lazyToast(
      runAsync(data),
      {
        loading: 'Replacing..',
        error: errorMessageResolver,
        success: 'Success!'
      },
      { duration: 5000 }
    )

    addToken(auth_token)

    reset()

    redirect('authLogin')
  }

  return (
    <section className={container}>
      <Toast
        title={reasonTitle}
        fullWidth
        kind="warning"
        type="outlined"
        icon="Notification"
        headerProps={{ className: notification }}
      >
        Please replace new password to login your account to{' '}
        <span className={descriptionHighlight}>{serviceName}</span>.
      </Toast>

      <header className={header}>
        <h2 className={title}>Replace Password</h2>
      </header>

      <ReplacePasswordForm
        onSuccess={onSuccess}
        disabled={loading}
        emailValue={emailValue}
      />
    </section>
  )
}

export default ForgotPassword
