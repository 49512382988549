import { computed, signal } from '@preact/signals-core'
import RegexParser from 'regex-parser'

import { SettingsResponse } from '$services/api/auth'

import { api } from '$model/api'

type SettingState = {
  loading: boolean
  checked: boolean

  data?: SettingsResponse['data']
}

export const settings = signal<SettingState>({ loading: false, checked: false })

export type PasswordRules = (typeof passwordRules)['value']
export const passwordRules = computed(() => {
  const { data } = settings.value
  const { password_rules = [] } = { ...data }

  return password_rules.map(({ regex, ...rest }) => ({
    ...rest,
    regex: RegexParser(regex)
  }))
})

export type Timezones = (typeof timezones)['value']
export const timezones = computed(() => {
  const { data } = settings.value
  const { timezones = [] } = { ...data }
  return timezones
})

const update = (value: Partial<SettingState>) => {
  settings.value = { ...settings.value, ...value }
}

export const refresh = async () => {
  const { settings, successResolver } = api.value.auth

  update({ loading: true })

  try {
    const { data } = await settings().then(successResolver)

    update({ data })
  } catch {
    update({ data: undefined })
  } finally {
    update({ loading: false, checked: true })
  }
}
