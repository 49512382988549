import { FC } from 'react'
import { WhatsappLogoProps } from './types'

const WhatsappLogo: FC<WhatsappLogoProps> = props => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C11.866 16 15 12.866 15 9C15 5.13401 11.866 2 8 2C4.13401 2 1 5.13401 1 9C1 10.2554 1.3305 11.4337 1.90924 12.4525L1 16L4.65743 15.1519C5.65069 15.6927 6.78946 16 8 16ZM8 14.9231C11.2712 14.9231 13.9231 12.2712 13.9231 9C13.9231 5.72878 11.2712 3.07692 8 3.07692C4.72878 3.07692 2.07692 5.72878 2.07692 9C2.07692 10.263 2.47225 11.4337 3.14592 12.3951L2.61538 14.3846L4.63997 13.8785C5.59468 14.5373 6.75229 14.9231 8 14.9231Z"
      fill="#BFC8D0"
    />
    <path
      d="M14 8.5C14 11.8137 11.3137 14.5 8 14.5C6.73608 14.5 5.56344 14.1092 4.59633 13.4418L2.54545 13.9545L3.08288 11.9392C2.40046 10.9653 2 9.77944 2 8.5C2 5.18629 4.68629 2.5 8 2.5C11.3137 2.5 14 5.18629 14 8.5Z"
      fill="url(#paint0_linear_8929_38701)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 9.75544 1.3305 10.9337 1.90924 11.9525L1 15.5L4.65743 14.6519C5.65069 15.1927 6.78946 15.5 8 15.5ZM8 14.4231C11.2712 14.4231 13.9231 11.7712 13.9231 8.5C13.9231 5.22878 11.2712 2.57692 8 2.57692C4.72878 2.57692 2.07692 5.22878 2.07692 8.5C2.07692 9.76303 2.47225 10.9337 3.14592 11.8951L2.61538 13.8846L4.63997 13.3785C5.59468 14.0373 6.75229 14.4231 8 14.4231Z"
      fill="white"
    />
    <path
      d="M6.25001 5.2497C6.08358 4.91541 5.82826 4.94501 5.57034 4.94501C5.10938 4.94501 4.39062 5.49715 4.39062 6.52473C4.39063 7.3669 4.76172 8.28877 6.01221 9.66781C7.21902 10.9987 8.80469 11.6872 10.1211 11.6637C11.4375 11.6403 11.7083 10.5075 11.7083 10.1249C11.7083 9.95535 11.6031 9.87075 11.5306 9.84777C11.082 9.63248 10.2547 9.23133 10.0664 9.15596C9.87816 9.08059 9.77986 9.18253 9.71876 9.23799C9.54804 9.40068 9.20963 9.88015 9.09376 9.98799C8.97789 10.0958 8.80514 10.0413 8.73325 10.0005C8.46872 9.89433 7.75146 9.57529 7.17973 9.02107C6.47266 8.33565 6.43117 8.09983 6.29795 7.88991C6.19138 7.72198 6.26958 7.61894 6.30861 7.57392C6.46095 7.39814 6.6713 7.12675 6.76564 6.99189C6.85997 6.85702 6.78508 6.65227 6.74015 6.52474C6.54689 5.97626 6.38316 5.51713 6.25001 5.2497Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8929_38701"
        x1="13.25"
        y1="4"
        x2="2"
        y2="14.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BD066" />
        <stop offset="1" stopColor="#27B43E" />
      </linearGradient>
    </defs>
  </svg>
)

export default WhatsappLogo
