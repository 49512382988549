import { FC, useMemo } from 'react'
import { format, parseISO } from 'date-fns'

import { Icon } from '@genie-fintech/ui/icons'
import { Spinner } from '@genie-fintech/ui/components'
import {
  StatusVariants,
  status as statusStyle
} from '@genie-fintech/ui/style/element'

import { GENERAL_TIME_FORMAT_WITH_TIMEZONE } from '$constants'

import useSignal from '$actions/useSignal'

import { ProfilePhoto } from '$elements/ProfilePhoto'

import { clipboardCopyText } from '$app/utilities'

import lazyToast from '$services/lazyToast'
import { profile } from '$store/profile'

import {
  profilePhotoFigure,
  accountIdTitle,
  accountIdValue,
  accountId,
  accountIdCopy,
  lastSignIn,
  info,
  accountName,
  accountAlias,
  container
} from './styles.css'

export const AccountInfo: FC = () => {
  const profileStore = useSignal(profile)

  const { name, phone, email, id, last_login_at, status, profile_photo_url } = {
    ...profileStore?.data
  }
  const isLoading = !!profileStore?.loading
  const statusKind: StatusVariants['kind'] = (() => {
    if (status == 'active') return 'success'
  })()
  const lastLoginTime = useMemo(() => {
    if (!last_login_at) return

    return format(parseISO(last_login_at), GENERAL_TIME_FORMAT_WITH_TIMEZONE)
  }, [last_login_at])

  const copyAccountID = () => {
    if (!id) return

    lazyToast(clipboardCopyText(`${id}`), {
      loading: 'Copying..',
      error: 'Failed to copy.',
      success: 'Copied!'
    })
  }

  return (
    <article data-testid="profile-info" className={container}>
      {isLoading ? (
        <Spinner
          color="neutral.100"
          style={{
            alignSelf: 'center',
            justifySelf: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        />
      ) : (
        <>
          <ProfilePhoto
            src={profile_photo_url}
            className={profilePhotoFigure}
          />

          <header className={info}>
            {name && (
              <h4 data-testid="profile-name" className={accountName}>
                {name}
              </h4>
            )}

            {email && (
              <p data-testid="profile-email" className={accountAlias}>
                {email}
              </p>
            )}

            {phone && (
              <p data-testid="profile-phone" className={accountAlias}>
                {phone}
              </p>
            )}

            {status && (
              <p
                className={statusStyle({
                  size: 'tiny',
                  type: 'faint',
                  kind: statusKind
                })}
                style={{ marginTop: 6 }}
              >
                {status.toUpperCase()}
              </p>
            )}
          </header>

          <div className={accountId}>
            <h4 className={accountIdTitle}>User ID</h4>

            <p className={accountIdValue} data-testid="profile-account-id">
              #{id}
            </p>

            <button
              type="button"
              className={accountIdCopy}
              onClick={copyAccountID}
            >
              <Icon namespace="Copy" />
            </button>
          </div>

          {lastLoginTime && (
            <p className={lastSignIn}>
              Last Signed In {lastLoginTime.toUpperCase()}
            </p>
          )}
        </>
      )}
    </article>
  )
}

export default AccountInfo
