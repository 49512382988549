import { useRequest, useBoolean } from 'ahooks'

import lazyToast from '$services/lazyToast'
import api from '$model/api'
import { addToken } from '$store/session'

import { LoginFormProps } from '$hook-forms/LoginForm'

import { useSuccessLogin } from './useSuccessLogin'
import { Login2FaBlockProps } from '$blocks/Login2FaBlock'
import { Token } from '$store/session/tokens'
import toast from 'react-hot-toast'
import { useId } from 'react'

const { login, errorMessageResolver, successResolver } = api.value.auth

export const useEmailLogin = () => {
  const LOGIN_SUCCESS_TOAST_ID = useId()

  const [is2FAModalOpen, { set: set2FAModal, setFalse: close2FAModal }] =
    useBoolean()

  const onLoginSuccess = useSuccessLogin()

  const loginRequest = useRequest(
    (...args: Parameters<typeof login>) => login(...args).then(successResolver),
    { manual: true }
  )

  const { available_factors, state_token } = {
    ...(() => {
      if (!loginRequest.data) return

      const { data } = loginRequest.data

      if (!data['2fa_required']) return

      return data
    })()
  }

  const resetState = () => {
    loginRequest.mutate()
  }

  const onTokenSuccess = (token: Token) => {
    const id = addToken(token)

    onLoginSuccess(id)
  }

  const onFormSubmit: LoginFormProps['onSuccess'] = async (reset, data) => {
    const { runAsync } = loginRequest

    const { data: result } = await lazyToast(
      runAsync(data),
      {
        loading: 'Verifying..',
        error: e => errorMessageResolver(e, 'Invalid credential'),
        success: ({ data, message }) => {
          if (data['2fa_required']) return message

          return 'Successfully logged in'
        }
      },
      { id: LOGIN_SUCCESS_TOAST_ID }
    )

    reset()
    set2FAModal(result['2fa_required'])

    if (result['2fa_required']) return

    onTokenSuccess(result.auth_token)
  }

  const secondFactor: Login2FaBlockProps['data'] = {
    available_factors,
    state_token
  }

  const loginForm: LoginFormProps = {
    onSuccess: onFormSubmit,
    disabled: is2FAModalOpen || loginRequest.loading
  }

  const forceDismissToast = () => toast.dismiss(LOGIN_SUCCESS_TOAST_ID)

  return {
    loginForm,
    is2FAModalOpen,
    close2FAModal,
    secondFactor,
    resetState,
    onTokenSuccess,
    forceDismissToast
  }
}

export default useEmailLogin
