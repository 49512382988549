import { FC } from 'react'

import { Spinner } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import { button } from '@genie-fintech/ui/style/element'

import useRouter from '$actions/useRouter'
import useSignal from '$actions/useSignal'
import usePageClass from '$browser/usePageClass'

import ChangePasswordButton from '$blocks/ChangePasswordButton'

import PageHead from '$layouts/AuthenticatedLayout/PageHead'
import PageRegion from '$layouts/AuthenticatedLayout/PageRegion'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'

import { profile } from '$store/profile'

import { content, description, pad } from './styles.css'

const pageName = 'ProfilePassword'

const Loader: FC = () => (
  <Spinner
    color="neutral.100"
    style={{
      alignSelf: 'center',
      justifySelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
    }}
  />
)

export const ProfilePassword: FC = () => {
  usePageClass({ name: pageName })

  const router = useRouter()
  const { title } = { ...router.info?.meta }

  const profileData = useSignal(profile)
  const { loading } = { ...profileData }
  const hasPassword = !!profileData?.data?.email

  return (
    <>
      <PageHead
        title={title}
        description="Manage and review user’s password for secure the system administration."
      />

      <PageRegion>
        <PageContents
          title="Password"
          description="Need a new password? Click the link below to update or reset it and ensure your account stays secure."
          mainClassName={content}
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              {hasPassword && (
                <>
                  <figure className={pad}>
                    <Icon namespace="Lock" />
                  </figure>

                  <p className={description}>
                    The password is encrypted and can not be seen. However, you
                    can still update.
                  </p>
                </>
              )}

              {!hasPassword && (
                <>
                  <figure className={pad}>
                    <Icon namespace="Warning" />
                  </figure>

                  <p className={description}>
                    Your account doesn't use password.
                  </p>
                </>
              )}

              <div>
                <ChangePasswordButton
                  className={button({ type: 'outlined', size: 'small' })}
                  disabled={!hasPassword}
                >
                  Update Password
                </ChangePasswordButton>
              </div>
            </>
          )}
        </PageContents>
      </PageRegion>
    </>
  )
}

export default ProfilePassword
