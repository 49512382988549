import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { BaseText } from '@genie-fintech/ui/components/fields'
import { toClassNames } from '@genie-fintech/ui/functions'

import { isNumeric } from '$app/utilities'
import { container, field, input } from './styles.css'

export type OTPInputProps = {
  className?: string
  length?: number
  disabled?: boolean
  onFilled: (passwords: string, reset: VoidFunction) => void
  autoFocus?: boolean
}

const initialValue = ''

export const OTPInput: FC<OTPInputProps> = ({
  length = 6,
  disabled,
  onFilled,
  autoFocus,
  className
}) => {
  const emptyPasswords = Array.from({ length }, () => initialValue)

  const containerRef = useRef<HTMLElement>(null)
  const [passwords, setPasswords] = useState(emptyPasswords)

  const safeValues = passwords.filter(isNumeric)
  const isFilled = length === safeValues.length
  const formattedValues = safeValues.join('')

  const setPassword = (value: string, index: number) => {
    setPasswords(old =>
      old.map((originalValue, currentKey) => {
        if (currentKey === index) return value

        return originalValue
      })
    )
  }
  const fillPasswords = (value: string) => {
    value.split('').filter(isNumeric).forEach(setPassword)
  }
  const resetPasswords = () => setPasswords(emptyPasswords)

  const focus = (index: number) => {
    const root = containerRef.current

    if (!root) return

    const input = root.querySelector<HTMLInputElement>(
      `input[data-index='${index}']`
    )

    if (!input) return

    input.focus()
  }
  const focusNext = (index: number) => {
    focus(index + 1)
  }
  const focusPrevious = (index: number) => {
    focus(index - 1)
  }
  const blur = useCallback(() => {
    const root = containerRef.current

    if (!root) return

    passwords.forEach((_, index) => {
      root
        .querySelector<HTMLInputElement>(`input[data-index='${index}']`)
        ?.blur()
    })
  }, [passwords])

  useEffect(() => {
    if (!isFilled) return

    onFilled(formattedValues, resetPasswords)
    blur()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilled, formattedValues, blur])

  // WebOTP
  useEffect(() => {
    const controller = new AbortController()

    // On non-localhost http, credentials could be undefined
    if (!navigator.credentials) return

    const waitOtp = navigator.credentials
      .get({
        signal: controller.signal,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        otp: {
          transport: ['sms']
        }
      })
      // avoid throwing error
      .catch(() => {})

    waitOtp.then(otp => {
      if (!otp) return

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      fillPasswords(otp.code)
    })

    return () => {
      controller.abort('Stopped listening otp')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <article ref={containerRef} className={toClassNames(container, className)}>
      {passwords.map((value, index) => (
        <BaseText
          key={index}
          placeholder="x"
          containerProps={{ className: field }}
          disabled={disabled}
          inputProps={{
            value,
            type: 'number',
            step: 1,
            min: 0,
            max: 9,
            maxLength: 1,
            'data-index': index,
            autoComplete: 'one-time-code',
            autoFocus: autoFocus && index == 0,
            className: input,
            onChange: e => {
              const value = e.target.value || ''

              // When tapping the OTP autocomplete, especially on mobile keyboard,
              // onPaste event doesn't get triggered.
              // this case handles it by checking the length of value
              // if the autocomplete value is valid, it should at least have the same length of input counts
              if (value.length === length) {
                fillPasswords(value)

                return
              }

              // pick only last character
              const [password = initialValue] = value
                .split('')
                .reverse()
                .filter(isNumeric)

              setPassword(password, index)

              if (password !== initialValue) {
                focusNext(index)
              }
            },
            onKeyUp: e => {
              const keyName = e.key.toLowerCase()

              if (keyName !== 'backspace') return

              setPassword(initialValue, index)
              focusPrevious(index)
            },
            onPaste: e => {
              e.preventDefault()

              fillPasswords(e.clipboardData.getData('text'))
            }
          }}
        />
      ))}
    </article>
  )
}

export default OTPInput
